<template>
  <div class="app-page">
    <Header ref="header">
      <template #header-slot>
        <BreadcrumbNavigation />
      </template>
      <template #page-slot>
        <BreadcrumbSelect
          id="project-select"
          v-model="currentProject"
          :options="projectList"
          :placeholder="'Project'"
          :displayTag="'name'"
        />
      </template>
    </Header>
    <div class="main-page-div">
      <main class="main-display-area">
        <ReviewsTab />
      </main>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import BreadcrumbSelect from '@/components/BreadcrumbSelect.vue';
import ReviewsTab from '@/components/ReviewsPage/ReviewsTab.vue';
import BreadcrumbNavigation from '@/components/BreadcrumbNavigation.vue';

export default {
  name: 'Tasks',
  components: {
    Header,
    ReviewsTab,
    BreadcrumbSelect,
    BreadcrumbNavigation,
  },
  data() {
    return {
      dataConnect: new DatastoreConnect(this.$store.state.enterpriseServerUrl),
      datasets: null,
    };
  },
  computed: {
    projectList() {
      return this.$store.state.projects.projectList;
    },
    currentProject: {
      get() {
        return this.$store.state.projects.currentProject;
      },
      set(value) {
        this.$store.commit('projects/setCurrentProject', value);
      },
    },
  },
  watch: {
    async currentProject() {
      this.refreshDatasetList();
    },
  },
  beforeMount() {
    this.refreshDatasetList();
  },
  created() {
    if (this.$route) {
      this.redirect = this.$route.name;
    }
  },
  methods: {
    async refreshDatasetList() {
      this.datasets = await this.getDatasets();
      this.getDatasetDetails();
    },
    async getDatasetDetails() {
      if (this.currentProject) {
        const params = {
          project_id: this.currentProject.id,
          get_num_images: true,
          get_labels: true,
          get_num_labels: true,
          get_annotation_sets: true,
          get_groups: true,
          get_parked: true,
        };
        const resp = await this.dataConnect.getDatasetList(params)
          .catch((error) => {
          // TODO: handle error
            console.log(error);
          });
        if (!resp.error) {
          const temp = resp.result;

          const shallowDatasets = [...this.datasets];

          shallowDatasets.forEach((e, i) => {
            temp[i].annotation_sets?.forEach((set, j) => {
              temp[i].annotation_sets[j].num_images = shallowDatasets[i].annotation_sets[j] ? shallowDatasets[i].annotation_sets[j].num_images : 0;
              temp[i].annotation_sets[j].num_annotations = shallowDatasets[i].annotation_sets[j] ? shallowDatasets[i].annotation_sets[j].num_annotations : 0;
            });
            shallowDatasets[i].annotation_sets = temp[i].annotation_sets;
            shallowDatasets[i].numImages = temp[i].numImages ? temp[i].numImages : 0;
            shallowDatasets[i].labels = temp[i].labels ? temp[i].labels : [];
            shallowDatasets[i].numLabels = temp[i].numLabels ? temp[i].numLabels : 0;
            shallowDatasets[i].groups = temp[i].groups ? temp[i].groups : [];
          });
          this.$store.commit('datasets/setDatasetList', shallowDatasets);
        }
      }
    },
    async getDatasets() {
      if (this.currentProject) {
        const params = {
          project_id: this.currentProject.id,
          // get_num_images: true,
          // get_labels: true,
          // get_num_labels: true,
          // get_annotation_sets: true,
          // get_groups: true,
          get_parked: true,
        };
        const resp = await this.dataConnect.getDatasetList(params)
          .catch((error) => {
          // TODO: handle error
            console.log(error);
          });
        let list = [];
        if (resp.error) {
          return list;
        } else {
          let temp = resp.result;

          temp = temp.map((e) => {
            e.annotation_sets = [];
            e.numImages = null;
            e.labels = null;
            e.numLabels = null;
            e.groups = null;
            return e;
          });

          list = temp;
        }
        return list;
      }
      return [];
    },
  },
};
</script>

<style lang="scss" scoped>

.tasks-filter-controls {
  display: flex;
  flex-direction: row;
  padding: 10px 20px 10px 20px;
  align-items: center;
  border-bottom: 1px solid #c9c9c9;
  @include themify() {
    background: themed('color-white');
  }
}
</style>
