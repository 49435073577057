<template>
  <div id="gallery-component-container" class="gallery">
    <template v-if="doneFetch && imageList && imageList.length > 0">
      <div v-if="showHeader" class="gallery__pagination">
        <div class="header-slot">
          <slot name="header-start" />
        </div>
        <v-pagination
          v-if="showPagination"
          id="gallery-page-selector"
          v-model="internalPage"
          class="pagination"
          :pages="internalTotalPages"
          :range-size="2"
          active-color="#7bb1db"
        />
        <div class="header-slot">
          <slot name="header-end" />
        </div>
      </div>
      <div class="gallery__grid-container" :style="gridContainerStyles">
        <div
          v-if="!hasGridItemSlot"
          id="gallery"
          ref="gallery"
          class="gallery__grid scrollbar"
          @scroll="handleScroll"
        >
          <GalleryGridItem
            v-for="imageObj in imageList"
            :key="imageObj.id"
            :imageObj="imageObj"
            :annotationSets="annotationSets"
            @image-deleted="$emit('image-deleted')"
            @grid-item-clicked="handleImageClicked(imageObj)"
          />
          <slot name="grid-item" />
        </div>
        <div
          v-else
          id="gallery"
          ref="gallery"
          class="gallery__grid scrollbar"
          :style="gridItemStyles"
          @scroll="handleScroll"
        >
          <slot name="grid-item" />
        </div>
      </div>
    </template>
    <div v-else-if="doneFetch && imageList && imageList.length == 0" class="empty-state">
      <slot name="empty-state" />
    </div>
    <slot name="overlay" />
  </div>
</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import { computed } from 'vue';
import InlineLoader from "@/components/InlineLoader.vue";
import GalleryGridItem from './GalleryGridItem.vue';

export default {
  name: 'GalleryGrid',
  components: {
    GalleryGridItem,
    VPagination,
    InlineLoader,
  },
  props: {
    doneFetch: {
      type: Boolean,
      default: true,
    },
    imageList: {
      type: Array,
      default: () => [],
    },
    totalImageCount: {
      type: Number,
      default: 0,
    },
    imagesPerPage: {
      type: Number,
      default: 40,
    },
    annotationSets: {
      type: Array,
      default: () => [],
    },
    page: {
      type: Number,
      default: 1,
    },
    totalPages: {
      type: Number,
      default: 0,
    },
    gridItemScale: {
      type: Number,
      default: null,
    },
    minGridItemHeight: {
      type: Number,
      default: 250,
    },
    minGridItemWidth: {
      type: Number,
      default: 250,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    showPagination: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    'update:page', 'update:totalPages', 'grid-item-clicked', 'image-deleted', 'scrolled-to-end',
  ],
  setup(_, { slots }) {
    const hasGridItemSlot = computed(() => slots['grid-item'] && slots['grid-item']());
    return {
      hasGridItemSlot,
    };
  },
  data() {
    return {
      scrollTop: 0,
      imageOffset: -1,
      imageLimit: 40,
    };
  },
  computed: {
    internalTotalPages() {
      return Math.ceil(this.totalImageCount / this.imagesPerPage);
    },
    internalPage: {
      get() { return this.page; },
      set(v) { this.$emit('update:page', v); },
    },
    gridItemStyles() {
      let style;
      if (this.gridItemScale < 1) {
        style = {
          '--grid-item-width': `${this.minGridItemWidth + (this.gridItemScale * this.minGridItemWidth)}px`,
          // '--grid-item-height': `${this.minGridItemHeight}px`,
        };
      }
      if (this.gridItemScale === 1) {
        style = {
          '--grid-item-width': `100%`,
          // '--grid-item-height': `100%`,
        };
      }
      return style;
    },
    gridContainerStyles() {
      if (this.gridItemScale === 1) {
        return {
          'justify-content': 'center',
        };
      }
      return null;
    },
  },
  watch: {
    internalTotalPages(totalPages) {
      this.$emit('update:totalPages', totalPages);
    },
  },
  created() {
    this.dsConn = new DatastoreConnect(this.$store.state.enterpriseServerUrl);
  },
  methods: {
    handleScroll() {
      const galleryScreen = document.getElementById('gallery');
      if (Math.ceil(galleryScreen.offsetHeight) + Math.ceil(galleryScreen.scrollTop) >= galleryScreen.scrollHeight) {
        this.$emit('scrolled-to-end');
      }
    },
    handleImageClicked(imageObj) {
      this.$emit('grid-item-clicked', imageObj);
    },
    handlePageUpdate() {

    },
  },
};
</script>

<style>
  #gallery-page-selector li button {
    padding: 1px 6px;
  }
</style>

<style lang="scss" scoped>

.gallery {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: hidden;
  position: relative;

  &__pagination {
    display: flex;
    margin: 20px 20px 0 20px;
  }

  &__grid-container {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    min-height: 0;
  }

  &__grid {
    --grid-item-width: 250px;
    // --grid-item-height: 250px;
    display: grid;
    grid-template-columns: repeat(auto-fill, var(--grid-item-width));
    // grid-auto-rows: var(--grid-item-height);
    grid-gap: 20px 20px;
    // height: auto;
    padding: 20px 20px 15px 20px;
    margin: 20px 0 20px 0;
    overflow: auto;
    transition: all 0.5s;
  }
}

.empty-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
}

.header-slot {
  flex: 1 1 50%;
}

.pagination {
  min-width: max-content;
}

</style>
