<template>
  <div class="animation-controls">
    <div class="timeline">
      <Scrubber
        v-model="frame"
        :min="1"
        :max="frameCount"
        :secondary="buffered"
        :step="1"
        @scrubbing-end="doneScrubbing"
      >
        <template #default="{ position, pendingValue }">
          <div
            class="scrubber-popup"
            :style="{ left: position }"
          >
            Frame {{ formatScrubberValue(pendingValue) }}
          </div>
          <div
            class="scrubber-thumb"
            :style="{ left: position }"
          />
        </template>
      </Scrubber>
    </div>
    <div class="controls">
      <div class="controls__start"><span v-if="frame && frameCount">Frame {{ frame }} / {{ frameCount }}</span></div>
      <div class="controls__buttons">
        <IconButton
          :icon="'skip_previous'"
          :width="24"
          :height="24"
          :type="'click'"
          @click="handleSkipPrevious"
        />
        <IconButton
          v-model="playing"
          aria-label="Play/Pause"
          :icon="'play'"
          :iconActive="'pause'"
          :width="32"
          :height="32"
          :type="'toggle-icon'"
          :showHover="true"
          @click="handlePlayButtonClick"
        />
        <IconButton
          :icon="'skip_next'"
          :width="24"
          :height="24"
          :type="'click'"
          @click="handleSkipNext"
        />
      </div>
      <div class="controls__end">
        <div class="annotation-search">
          <AnnotationExpandingSearch
            :labels="labels"
            @previous="handlePreviousAnnotationClicked"
            @next="handleNextAnnotationClicked"
          />
        </div>
        <v-number-input
          id="fps-input"
          v-model="fps"
          class="fps-input"
          :reverse="false"
          controlVariant="stacked"
          label="FPS"
          :hideInput="false"
          :hide-details="true"
          :inset="false"
          variant="outlined"
          :min="1"
          :max="20"
          density="compact"
        />
        <SVGIcon
          v-if="currentAnimationSample && currentAnimationSample?.imageObj?.review_status == 'Done'"
          class="verified_badge"
          :iconName="'verified_filled'"
          :width="'24px'"
          :height="'24px'"
        />
      </div>
    </div>
    <div>
      <KeyframeTimeline2
        v-if="doKeyframesExist"
        v-model:frame="frame"
        v-model:data="keyframes"
        :frameCount="frameCount"
        class="mt-2 mb-2"
      />
    </div>
  </div>
</template>

<script setup>
import {
  ref, computed, onMounted, onUnmounted, watch, toRefs,
} from 'vue';
import { useViewerVisualizationsStore } from '@/stores/useViewerVisualizationsStore.js';
import { storeToRefs } from 'pinia';
import useImagesFetch from '@/composables/useImagesFetch.js';
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import IconButton from '@/components/IconButton.vue';
import Scrubber from '@/components/DatasetComponent/AnnotationTool/VideoControls/Scrubber.vue';
import usePCD from '@/composables/annotationTool/usePCD.js';
import KeyframeTimeline2 from '@/components/DatasetComponent/AnnotationTool/KeyframeTimeline2.vue';
import SVGIcon from '@/components/SVGIcon.vue';
import pLimit from 'p-limit';
import { useStore } from 'vuex';
import AnnotationExpandingSearch from '@/components/DatasetComponent/AnnotationTool/AnnotationExpandingSearch.vue';

const store = useStore();

const props = defineProps({
  filters: {
    type: Object,
    default: null,
  },
  galleryParams: {
    type: Object,
    default: null,
  },
  currentImage: {
    type: Object,
    default: null,
  },
  isTask: {
    type: Boolean,
    default: false,
  },
  reviewSettings: {
    type: Object,
    default: null,
  },
  labels: {
    type: Array,
    default: () => [],
  },
  selectedAnnotationSets: {
    type: Array,
    default: () => [],
  },
});
const {
  currentImage,
  labels,
  selectedAnnotationSets,
} = toRefs(props);

const emit = defineEmits(['frame-count']);
defineExpose({ handleSkipPrevious, handleSkipNext, handleClearCache });

const visualizationStore = useViewerVisualizationsStore();
const {
  animationImageCache, frame, playing, buffered, currentAnimationSample, keyframes, doKeyframesExist, frameCount, fps, isInitializingAnimation,
} = storeToRefs(visualizationStore);
const {
  startAnimation, pauseAnimation, updateSample, resetForNewSequence, $reset,
} = visualizationStore;

const { controller, getImages, getLabellingSequenceFrames } = useImagesFetch();

const { parsePCDFile } = usePCD();

function getTopics(samples) {
  return [...new Set(samples.map((item) => item.annotations).flat().map((item) => item.type))];
}

let samples = [];
let topics = null;

const max_concurrent_req = 4;
let limit = null;

const cacheTypes = ['pcd'];

const blobCacheTypes = ['depthmap'];

let abortControllerForCaching = null;
let abortSignalForCaching = null;

async function doneScrubbing() {
  if (!isInitializingAnimation.value && frameCount.value > 0) {
    abortCaching();
    if (!animationImageCache.value[frame.value - 1].loaded) {
      preloadSample(samples, frame.value - 1).then(() => { updateSample(); });
    }
    preloadSamples(samples, frame.value - 1, abortSignalForCaching);
  }
}

async function loadImage(imageObj, sampleIndex) {
  return new Promise((resolve, reject) => {
    fetch(`image/redirect/${imageObj.id}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${store.state.user.token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Error loading image`);
        }
        return response.blob();
      })
      .then((blob) => {
        const image = document.createElement('img');
        image.onerror = (e) => { console.log(e); };
        image.onload = () => {
          try {
            animationImageCache.value[sampleIndex].image = image;
            animationImageCache.value[sampleIndex].imageObj = imageObj;

            // Add review status to annotation if it exists
            if (imageObj.review_status === 'Done' && imageObj.annotations) {
              imageObj.annotations.forEach((anno) => {
                anno.reviewStatus = 'verified';
              });
            }

            resolve();
          } catch {
            reject();
          }
        };
        image.src = URL.createObjectURL(blob);
      })
      .catch((e) => {
        console.log(e);
      });
  });
}

function loadAnnotationUrls(imageObj, sampleIndex) {
  if (imageObj.annotations) {
    animationImageCache.value[sampleIndex].annotations = [...imageObj.annotations];
    const annotationPromises = [];
    const dataConnect = new DatastoreConnect();
    animationImageCache.value[sampleIndex].annotations.forEach((anno) => {
      if (anno.data_url && cacheTypes.includes(anno.type)) {
        annotationPromises.push(
          limit(async () => {
            const pcdFile = await dataConnect.getAnnotationFile(anno.id);
            anno.data = pcdFile;
            parseAnnotation(anno, sampleIndex);
          }),
        );
      } else if (anno.data_url && blobCacheTypes.includes(anno.type)) {
        annotationPromises.push(
          limit(async () => {
            const blob = await dataConnect.getAnnotationFile(anno.id, true);
            anno.data = blob;
            parseAnnotation(anno, sampleIndex);
          }),
        );
      } else if (anno.polygon && cacheTypes.includes(anno.type)) {
        annotationPromises.push(
          limit(() => {
            anno.data = anno.polygon;
            parseAnnotation(anno, sampleIndex);
          }),
        );
      }
    });
    return annotationPromises;
  }
}

async function parseAnnotation(annotation, sampleIndex) {
  if (animationImageCache.value[sampleIndex] && annotation.type === 'pcd' && annotation.data) {
    animationImageCache.value[sampleIndex].pcd.push(parsePCDFile(annotation.data));
  }
  if (animationImageCache.value[sampleIndex] && annotation.type === 'depthmap' && annotation.data) {
    const image = new Image();
    image.onload = () => {
      animationImageCache.value[sampleIndex].depthmap.push(image);
    };
    image.onerror = (e) => { console.log(e); };

    const url = URL.createObjectURL(annotation.data);
    image.src = url;
  }
}

async function loadImageAndAnnotations(sample, index, localLimit, localSignal) {
  await Promise.allSettled([
    localLimit(() => loadImage(sample, index)),
    ...loadAnnotationUrls(sample, index),
  ]);
  if (localSignal && localSignal.aborted) {
    return Promise.reject(new Error(`Loading sample ${index} aborted`));
  } else {
    animationImageCache.value[index].loaded = true;
    return Promise.resolve();
  }
}

// Create image cache
async function preloadSamples(samples, startIndex, localSignal) {
  limit = pLimit(max_concurrent_req);
  localSignal.addEventListener('abort', () => {
    limit.clearQueue();
  });
  const promises = [];
  for (let i = startIndex; i < samples.length; i++) {
    if (!animationImageCache.value[i] || (animationImageCache.value[i] && !animationImageCache.value[i].loaded)) {
      animationImageCache.value[i] = {
        image: null,
        imageObj: samples[i],
        annotations: null,
        loaded: false,
        ...Object.fromEntries(topics.map((key) => [key, []])),
      };
      promises.push(loadImageAndAnnotations(samples[i], i, limit, localSignal));
    }
  }

  await Promise.all(promises)
    .catch((err) => {
      // Do nothing
    });
}

async function preloadSample(samples, index) {
  if (!animationImageCache.value[index] || (animationImageCache.value[index] && !animationImageCache.value[index].loaded)) {
    animationImageCache.value[index] = {
      image: null,
      imageObj: null,
      annotations: null,
      loaded: false,
      ...Object.fromEntries(topics.map((key) => [key, []])),
    };
  }
  await Promise.all([
    loadImage(samples[index], index),
    ...loadAnnotationUrls(samples[index], index),
  ]);
  animationImageCache.value[index].loaded = true;
}

onMounted(async () => {
  // await cacheImages();
});

onUnmounted(async () => {
  if (abortControllerForCaching) {
    abortControllerForCaching.abort();
  }
  $reset();
});

watch(currentImage, () => {
  if (abortControllerForCaching) {
    abortControllerForCaching.abort();
  }
  resetForNewSequence();
  cacheImages();
}, { immediate: true });

async function cacheImages() {
  if (controller.value) {
    controller.value.abort();
  }
  controller.value = new AbortController();
  const currentSignal = controller.value.signal;

  isInitializingAnimation.value = true;
  const filters = {
    images_filter: {
      sequence_id: currentImage.value.sequence_id,
    },
    image_files_filter: {},
  };

  // let images = [];
  if (props.isTask) {
    filters.images_filter.dataset_id = props.reviewSettings.reviewTask.dataset_id;
    const options = {
      sort_by: 'id',
      reverse: false,
      offset: 0,
      source_annotation_set_id: props.reviewSettings.reviewTask.source_annotation_set_id,
      annotations_filter: null,
      aggregate_annotations_filter: { aggregate_annotation_set_ids: [props.reviewSettings.reviewTask.dest_annotation_set_id] },
      review_task_id: props.reviewSettings.reviewTask.id,
    };
    samples = await getLabellingSequenceFrames(filters, options, currentSignal)
      .catch((err) => {
        throw Error(err);
      });
  } else {
    filters.images_filter.dataset_id = currentImage.value.dataset_id;
    const options = {
      sort_by: 'id',
      reverse: false,
      offset: 0,
    };
    samples = await getImages(filters, options, currentSignal)
      .catch((err) => {
        throw Error(err);
      });
  }

  frameCount.value = samples.length;
  emit('frame-count', samples.length);
  topics = getTopics(samples);

  if (!currentSignal.aborted) {
    abortCaching();
    isInitializingAnimation.value = false;
    preloadSamples(samples, 0, abortSignalForCaching);
  }
}

function formatScrubberValue(v) {
  return v;
}

function handlePlayButtonClick() {
  playing.value ? pauseAnimation() : startAnimation();
}

function handleSkipPrevious() {
  if (frame.value - 1 > 0) {
    frame.value -= 1;
    if (!animationImageCache.value[frame.value - 1].loaded) {
      abortCaching();
      preloadSample(samples, frame.value - 1).then(() => { updateSample(); });
      preloadSamples(samples, frame.value - 1, abortSignalForCaching);
    }
  }
}

function handleSkipNext() {
  if (frame.value + 1 <= animationImageCache.value.length) {
    frame.value += 1;
  }
}

function handleClearCache() {
  animationImageCache.value = [];
  abortCaching();
  preloadSample(samples, frame.value).then(() => { updateSample(); });
  preloadSamples(samples, frame.value, abortSignalForCaching);
}

function abortCaching() {
  if (abortControllerForCaching) {
    abortControllerForCaching.abort();
  }
  abortControllerForCaching = new AbortController();
  abortSignalForCaching = abortControllerForCaching.signal;
}

function handlePreviousAnnotationClicked(labelsSelected) {
  const potentialLabels = labelsSelected.map((label) => label.name);
  const foundFrameIndex = animationImageCache.value.findLastIndex((currFrame, i) => {
    const hasAnnotation = currFrame.annotations.some((annotation) => potentialLabels.includes(annotation.label_name) && selectedAnnotationSets.value.includes(annotation.annotation_set_id));
    const isLessThanCurrentFrame = i < (frame.value - 1);
    return hasAnnotation && isLessThanCurrentFrame;
  });
  if (foundFrameIndex >= 0) {
    frame.value = foundFrameIndex + 1;
  }
}

function handleNextAnnotationClicked(labelsSelected) {
  const potentialLabels = labelsSelected.map((label) => label.name);
  const foundFrameIndex = animationImageCache.value.findIndex((currFrame, i) => {
    const hasAnnotation = currFrame.annotations.some((annotation) => potentialLabels.includes(annotation.label_name) && selectedAnnotationSets.value.includes(annotation.annotation_set_id));
    const isMoreThanCurrentFrame = i > (frame.value - 1);
    return hasAnnotation && isMoreThanCurrentFrame;
  });
  if (foundFrameIndex >= 0) {
    frame.value = foundFrameIndex + 1;
  }
}

</script>

<style lang="scss" scoped>
.animation-controls {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 70px;
  padding: 0 8px;
  z-index: 10;
  border-left: 1px solid rgba(0,0,0,0.25);
  background: var(--body-color);
}

.timeline {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 20px;
  padding: 8px 0;
}

.controls {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 50px;

  &__start {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1 1 1px;
  }

  &__end {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1 1 1px;
    gap: 8px;
  }

  &__input {
    max-width: 100px;
  }

  &__buttons {
    max-width: 100px;
  }
}

.scrubber-popup {
  position: absolute;
  transform: translateX(-50%);
  background-color: #000;
  border-radius: 0.375rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  bottom: 0;
  margin-bottom: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 0.75rem;
  color: #fff;
  pointer-events: none;
}
.scrubber-thumb {
  position: absolute;
  transform: translate(-50%, 7px);
  background-color: var(--color-primary-400);
  height: 18px;
  width: 4px;
  bottom: 0;
}

.verified_badge {
  color: var(--color-success);
}

.fps-input {
  max-width: 100px;
}

.annotation-search {
  padding: 4px 0px;
  margin-left: auto;
  max-width: 300px;
}
</style>
