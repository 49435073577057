import {
  onUnmounted,
} from 'vue';
import { storeToRefs } from 'pinia';
import { useAnnotationTypeStore } from '@/stores/useAnnotationTypeStore.js';

export default function useAnnotationType() {
  const {
    allTypes,
    selectedDisplayTypes,
  } = storeToRefs(useAnnotationTypeStore());

  const {
    $reset,
  } = useAnnotationTypeStore();

  onUnmounted(() => {
    $reset();
  });

  function getTypeDisplayString(str) {
    let result = "";
    switch (str) {
    case 'pcd':
      result = "PCD";
      break;
    case 'radar':
      result = "Radar";
      break;
    case 'gps':
      result = "GPS";
      break;
    case 'imu':
      result = "IMU";
      break;
    case 'box':
      result = "2D Bounding Box";
      break;
    case 'seg':
      result = "2D Polygon";
      break;
    case 'mask':
      result = "Mask File";
      break;
    case '3dbox':
      result = "3D Bounding Box";
      break;
    case 'cube':
      result = "Cube File";
      break;
    case 'scales':
      result = "Scales File";
      break;
    case 'depthmap':
      result = "Depth Map";
      break;
    default:
      break;
    }
    return result;
  }

  return {
    getTypeDisplayString,
    allTypes,
    selectedDisplayTypes,
  };
}
