<template>
  <div
    v-if="show && !isReviewMode"
    id="annotationToolRibbon"
    class="ribbon"
  >
    <div class="ribbon__group">
      <span>{{ zoom }}%</span>
      <IconButton
        id="zoomToFitBtn"
        class="ribbon__button"
        aria-label="Zoom To Fit (Z)"
        :icon="'zoom_to_extents'"
        :showHover="false"
        :type="''"
        :title="'Zoom To Fit (Z)'"
        @click="handleZoomToFit"
      />
      <IconButton
        id="zoomToSelectionBtn"
        class="ribbon__button"
        aria-label="Zoom To Selection (Selection + Z)"
        :disabled="!hasSelection"
        :icon="'zoom_to_selection'"
        :showHover="false"
        :type="''"
        :title="'Zoom To Selection (Selection + Z)'"
        @click="handleZoomToSelection"
      />
      <hr class="ribbon__vr">
      <IconButton
        id="undoBtn"
        class="ribbon__button"
        aria-label="Undo"
        :disabled="!canUndo"
        :icon="'undo'"
        :showHover="false"
        :type="''"
        :title="'Undo'"
        @click="handleUndo"
      />
      <IconButton
        id="revertChangesBtn"
        class="ribbon__button"
        aria-label="Revert All Changes"
        :icon="'restore'"
        :showHover="false"
        :type="''"
        :title="'Revert All Changes'"
        @click="handleRevertChanges"
      />
      <IconButton
        id="delBtn"
        class="ribbon__button"
        aria-label="Delete Annotations"
        :icon="'remove_selection'"
        :showHover="false"
        :type="''"
        :title="'Delete Annotations (ctrl + x)'"
        @click="handleDeleteAnnotationsBulk"
      />
      <IconButton
        id="delBtn"
        class="ribbon__button"
        aria-label="Open 3D Display (d)"
        :icon="'3d'"
        :showHover="false"
        :type="''"
        :title="'Open 3D Display (d)'"
        @click="show3d"
      />
    </div>
    <div class="ribbon__group">
      <!-- <IconButton
        v-if="hasSelection"
        id="showAnnotationInfoBtn"
        class="ribbon__button"
        aria-label="Show Annotation Info"
        :icon="'auto'"
        :showHover="false"
        :type="''"
        :title="'Show Annotation Info'"
        @click="handleShowAnnotationInfo"
      /> -->
      <hr class="ribbon__vr">
      <button
        v-if="hasReviewTask"
        class="button button-secondary ribbon__button-secondary"
        :title="'Skip Image (Right Arrow)'"
        @click="handleSkipImage"
      >
        Skip Image
      </button>
      <button
        class="button ribbon__button-primary"
        :title="'Submit Annotations (Enter)'"
        @click="handleSubmit"
      >
        Submit
      </button>
    </div>
  </div>
  <div v-if="show && isReviewMode" id="annotationToolRibbon" class="ribbon">
    <div class="ribbon__group">
      <IconButton
        id="undoBtn"
        class="ribbon__button"
        aria-label="Undo"
        :disabled="!canUndo"
        :icon="'undo'"
        :showHover="false"
        :type="''"
        :title="'Undo'"
        @click="handleUndo"
      />
      <IconButton
        id="revertChangesBtn"
        class="ribbon__button"
        aria-label="Revert All Changes"
        :icon="'restore'"
        :showHover="false"
        :type="''"
        :title="'Revert All Changes'"
        @click="handleRevertChanges"
      />
      <IconButton
        id="delBtn"
        class="ribbon__button"
        aria-label="Delete Annotations"
        :icon="'remove_selection'"
        :showHover="false"
        :type="''"
        :title="'Delete Annotations (ctrl + x)'"
        @click="handleDeleteAnnotationsBulk"
      />
      <IconButton
        id="delBtn"
        class="ribbon__button"
        aria-label="Open 3D Display (d)"
        :icon="'3d'"
        :showHover="false"
        :type="''"
        :title="'Open 3D Display (d)'"
        @click="show3d"
      />
    </div>
    <div class="ribbon__group">
      <IconButton
        v-if="hasSelection"
        id="showAnnotationInfoBtn"
        class="ribbon__button"
        aria-label="Show Annotation Info"
        :icon="'auto'"
        :showHover="false"
        :type="''"
        :title="'Show Annotation Info'"
        @click="handleShowAnnotationInfo"
      />
      <button
        v-if="hasReviewTask"
        class="button button-danger ribbon__button-secondary button-flex"
        :title="'Delete Image'"
        @click="handleDeleteImageFromDataset"
      >
        <SVGIcon
          class="me-1"
          :iconName="'delete'"
          :width="'20px'"
          :height="'20px'"
        />
        Delete Image
      </button>
      <button
        v-if="hasReviewTask"
        class="button button-secondary ribbon__button-secondary"
        :title="'Skip Image (Right Arrow)'"
        @click="handleSkipImage"
      >
        Skip Image
      </button>
      <BasePersistentSplitButton
        v-model="submitType"
        class="ribbon__button-primary"
        title="Submit Annotations (Enter)"
        :options="submitTypes"
        @click="handleReviewSubmit"
      />
    </div>
  </div>
</template>

<script>
import SVGIcon from '@/components/SVGIcon.vue';
import IconButton from '@/components/IconButton.vue';
import BasePersistentSplitButton from '@/components/BasePersistentSplitButton.vue';
import useTasks from '@/composables/annotationTool/useTasks.js';

export default {
  name: 'AnnotationToolRibbon',
  components: {
    SVGIcon,
    IconButton,
    BasePersistentSplitButton,
  },
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    zoom: {
      type: Number,
      default: 100,
    },
    canUndo: {
      type: Boolean,
      default: false,
    },
    hasSelection: {
      type: Boolean,
      default: false,
    },
    labels: {
      type: Array,
      default: () => [],
    },
    annotationSets: {
      type: Array,
      default: () => [],
    },
    annotationDisplaySettings: {
      type: Object,
      default: null,
    },
    isReviewMode: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'zoom-to-fit',
    'zoom-to-selection',
    'undo',
    'revert-changes',
    'show-annotation-info',
    'skip',
    'submit',
    'submit-all-annotations',
    'submit-accepted-annotations',
    'bulk-del-ann',
    'exit-editor', 'toggle3d',
  ],
  setup() {
    const {
      internalImageObj,
      submitType,
      skipTaskImage,
      handleAuditSubmitAllAnnotations,
      handleAuditSubmitAcceptedAnnotations,
      deleteTaskImageFromDataset,
      hasReviewTask,
    } = useTasks();

    return {
      internalImageObj,
      submitType,
      skipTaskImage,
      handleAuditSubmitAllAnnotations,
      handleAuditSubmitAcceptedAnnotations,
      deleteTaskImageFromDataset,
      hasReviewTask,
    };
  },
  data() {
    return {
      internalAnnotationDisplaySettings: null,
      SAMActive: false,
      submitTypes: [
        { title: 'Submit All Annotations', value: 'submit-all-annotations' },
        { title: 'Submit Accepted Annotations', value: 'submit-accepted-annotations' },
      ],
    };
  },
  mounted() {
    if (this.annotationDisplaySettings) {
      this.internalAnnotationDisplaySettings = this.annotationDisplaySettings;
    }
  },
  methods: {
    handleZoomToFit() {
      this.$emit('zoom-to-fit');
    },
    handleZoomToSelection() {
      this.$emit('zoom-to-selection');
    },
    handleRevertChanges() {
      this.$emit('revert-changes');
    },
    handleUndo() {
      this.$emit('undo');
    },
    handleShowAnnotationInfo() {
      this.$emit('show-annotation-info');
    },
    async handleDeleteImageFromDataset() {
      const resp = await this.deleteTaskImageFromDataset(this.internalImageObj?.id)
        .catch((error) => {
          // TODO: handle error
        });
    },
    handleSkipImage() {
      this.$emit('skip');
      this.skipTaskImage();
    },
    handleSubmit() {
      this.$emit('submit');
    },
    async handleReviewSubmit(submitType) {
      if (submitType === 'submit-all-annotations') {
        await this.handleAuditSubmitAllAnnotations();
      } else if (submitType === 'submit-accepted-annotations') {
        await this.handleAuditSubmitAcceptedAnnotations();
      }

      if (!this.hasReviewTask) {
        this.$emit('exit-editor');
      }
    },
    handleEnter() {
      this.$emit(this.submitType);
    },
    handleDeleteAnnotationsBulk() {
      this.$emit('bulk-del-ann');
    },
    show3d() {
      this.$emit('toggle3d');
    },

  },
};
</script>

<style lang="scss" scoped>
.ribbon {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  min-height: 37px;
  max-height: 37px;
  padding: 2px 16px;
  justify-content: space-between;
  z-index: 10;
  box-shadow: 0 3px 3px -3px gray, 0 10px 10px -10px gray;
  color: var(--ribbon-icons-color);
  background: var(--ribbon-bg);
  border-left: solid 1px var(--ribbon-border);

  &__group {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }

  &__vr {
    display: flex;
    width: 1px;
    height: 60%;
    margin: 0;
  }

  &__button {
    display: flex;
    min-width: 22px;
    height: 22px;
    color: inherit;
    background: none;
    border: none;
    font-size: 0.875rem;
    line-height: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .05em;
    align-items: center;
    cursor: pointer;

    &:hover:not(:disabled), &.active:not(:disabled) {
      box-shadow: 0 0 0 4px var(--icon-hover-color);
      background-color: var(--icon-hover-color);
      border-radius: 4px;
    }

    &:disabled {
      cursor: default;
      color: var(--color-disabled);
    }
  }

  &__button-primary {
    display: flex;
    height: 28px;
    font-size: 0.875rem;
    line-height: 1rem;
    align-items: center;
  }

  &__button-secondary {
    display: flex;
    height: 28px;
    font-size: 0.875rem;
    line-height: 1rem;
    align-items: center;
  }

  &__labeled_button {
    width: auto;
  }
}

:deep(.filter-button) {
  height: 22px;
}

</style>
