<template>
  <div class="app-page">
    <Header ref="header">
      <template #header-slot>
        <BreadcrumbNavigation />
      </template>
      <template v-if="!$route.fullPath.includes('/viewer')" #page-slot>
        <BreadcrumbSelect
          id="project-select"
          v-model="currentProject"
          :options="projectList"
          :placeholder="'Project'"
          :displayTag="'name'"
        />
        <SVGIcon
          :iconName="'arrow_right'"
          :width="'32px'"
          :height="'32px'"
        />
        <BreadcrumbSelect
          id="dataset-select"
          v-model="currentDataset"
          :options="datasets"
          :placeholder="'Dataset'"
          :displayTag="'name'"
        />
      </template>
      <template v-else #page-slot>
        <span v-if="imageName" class="mt-0 mb-0">{{ imageName }}</span>
      </template>
    </Header>
    <div class="main-page-div">
      <main
        id="drop-area"
        class="main-display-area"
      >
        <router-view />
      </main>
    </div>
    <TasksModal />
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import BreadcrumbSelect from '@/components/BreadcrumbSelect.vue';
import SVGIcon from '@/components/SVGIcon.vue';
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import TasksModal from '@/components/TasksStatusComponent/TasksModal.vue';
import BreadcrumbNavigation from '@/components/BreadcrumbNavigation.vue';

export default {
  name: 'Datasets',
  components: {
    Header,
    BreadcrumbSelect,
    SVGIcon,
    TasksModal,
    BreadcrumbNavigation,
  },
  data() {
    return {
      imageName: '',
      dataConnect: new DatastoreConnect(this.$store.state.enterpriseServerUrl),
    };
  },
  computed: {
    projectList() {
      return this.$store.state.projects.projectList;
    },
    currentProject: {
      get() {
        return this.$store.state.projects.currentProject;
      },
      set(value) {
        this.$store.commit('projects/setCurrentProject', value);
      },
    },
    currentDataset: {
      get() {
        return this.$store.state.datasets.currentDataset;
      },
      set(d) {
        this.$store.commit('datasets/setCurrentDataset', d);
      },
    },
    datasets() {
      return this.$store.state.datasets.datasetList;
    },
    combined() {
      return { ...this.currentProject, ...this.currentDataset };
    },
  },
  watch: {
    async currentProject() {
      this.currentDataset = null;
      // this.refreshDatasetList();
    },
    combined: {
      deep: true,
      handler() {
        this.$router.replace({
          path: this.$route.path,
          query: {
            dataset: this.currentProject?.id,
            project: this.currentDataset?.id,
          },
        });
      },
    },
    '$route.query.image': {
      handler() {
        if (this.$route.query.image) {
          this.imageName = JSON.parse(decodeURI(this.$route.query.image)).name;
        }
      },
    },
  },
  created() {
    if (this.$route) {
      this.redirect = this.$route.name;
    }
  },
  async beforeMount() {
    // this.refreshDatasetList();
  },

  mounted() {
    if (this.$route.query.dataset) {
      const d = this.datasets.find((e) => e.id === this.$route.query.dataset);
      if (d) {
        this.$store.commit('datasets/setCurrentDataset', d);
      }
    }
    if (this.$route.query.project) {
      const d = this.projectList.find((e) => e.id === this.$route.query.project);
      if (d) {
        this.$store.commit('datasets/setCurrentProject', d);
      }
    }
    if (this.$route.query.image) {
      this.imageName = JSON.parse(decodeURI(this.$route.query.image)).name;
    }
  },
  methods: {
    async refreshDatasetList() {
      const datasets = await this.getDatasets();
      this.$store.commit('datasets/setDatasetList', datasets);
      this.$nextTick(() => {
        if (!this.currentDataset && this.datasets && this.datasets.length > 0) {
          this.currentDataset = this.datasets[0];
        }
      });
      await this.getDatasetDetails();
    },
    async getDatasetDetails() {
      const currentProject = this.$store.state.projects.currentProject;
      if (currentProject) {
        const params = {
          project_id: currentProject.id,
          get_num_images: true,
          get_labels: true,
          get_num_labels: true,
          get_annotation_sets: true,
          get_groups: true,
          get_parked: true,
        };
        const resp = await this.dataConnect.getDatasetList(params)
          .catch((error) => {
          // TODO: handle error
            console.log(error);
          });
        if (!resp.error) {
          const temp = resp.result;

          const shallowDatasets = [...this.datasets];

          shallowDatasets.forEach((e, i) => {
            temp[i].annotation_sets?.forEach((set, j) => {
              temp[i].annotation_sets[j].num_images = shallowDatasets[i].annotation_sets[j] ? shallowDatasets[i].annotation_sets[j].num_images : 0;
              temp[i].annotation_sets[j].num_annotations = shallowDatasets[i].annotation_sets[j] ? shallowDatasets[i].annotation_sets[j].num_annotations : 0;
            });
            shallowDatasets[i].annotation_sets = temp[i].annotation_sets;
            shallowDatasets[i].numImages = temp[i].numImages ? temp[i].numImages : 0;
            shallowDatasets[i].labels = temp[i].labels ? temp[i].labels : [];
            shallowDatasets[i].numLabels = temp[i].numLabels ? temp[i].numLabels : 0;
            shallowDatasets[i].groups = temp[i].groups ? temp[i].groups : [];
          });
          this.$store.commit('datasets/setDatasetList', shallowDatasets);
        }
      }
    },
    async getDatasets() {
      const currentProject = this.$store.state.projects.currentProject;
      if (currentProject) {
        const params = {
          project_id: currentProject.id,
          // get_num_images: true,
          // get_labels: true,
          // get_num_labels: true,
          // get_annotation_sets: true,
          // get_groups: true,
          get_parked: true,
        };
        const resp = await this.dataConnect.getDatasetList(params)
          .catch((error) => {
          // TODO: handle error
            console.log(error);
          });
        let list = [];
        if (resp.error) {
          return list;
        } else {
          let temp = resp.result;

          temp = temp.map((e) => {
            e.annotation_sets = [];
            e.numImages = null;
            e.labels = null;
            e.numLabels = null;
            e.groups = null;
            return e;
          });

          list = temp;
        }
        return list;
      }
      return [];
    },
  },
};
</script>

<style lang="scss" scoped>
span {
  font-size: 1.0rem;
  font-weight: 600;
  padding: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
