<template>
  <div class="controls-bar">
    <DatasetFilter
      v-model="datasetFilters"
      :dataset="currentDataset"
      :annotationSets="annotationSets"
      :labels="currentLabels"
      :showCopyFilteredImagesBtn="false"
      :types="currentTypes"
    />
  </div>
  <div id="map" />
</template>

<script>
import DatasetFilter from '@/components/DatasetComponent/DatasetFilters/DatasetFilter.vue';
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import { Map, View, Feature } from 'ol';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { Cluster, OSM, Vector as VectorSource } from 'ol/source';
import { Point } from 'ol/geom';
import { fromLonLat } from 'ol/proj';
import { boundingExtent } from 'ol/extent';
import { Select } from 'ol/interaction';
import {
  Circle as CircleStyle,
  Fill,
  Stroke,
  Style,
  Text,
} from 'ol/style';
import 'ol/ol.css';

export default {
  name: 'Map',
  components: {
    DatasetFilter,
  },
  props: {
    show: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      dataConnect: new DatastoreConnect(this.$store.state.enterpriseServerUrl),
      map: null,
      markersLayer: null,
      markersSource: null,
      clusterSource: null,
      imageList: [],
      imagesLimit: 10000,
      datasetFilters: {},
      controller: new AbortController(),
    };
  },
  computed: {
    currentDataset() {
      return this.$store.state.datasets.currentDataset;
    },
    getImagesParams() {
      if (!this.currentDataset) {
        return null;
      }

      const params = {
        images_filter: { dataset_id: this.currentDataset.id },
        image_files_filter: {},
        annotations_filter: {},
      };

      if (this.imagesLimit) {
        params.limit = this.imagesLimit;
      }

      // Images filter
      if (this.datasetFilters.groupsFilter && this.datasetFilters.groupsFilter.length > 0) {
        params.images_filter.group_ids = this.datasetFilters.groupsFilter;
      }

      // Annotations filter
      if (this.datasetFilters.confidenceRangeFilter) {
        params.annotations_filter.lower_score = this.datasetFilters.confidenceRangeFilter[0];
        params.annotations_filter.upper_score = this.datasetFilters.confidenceRangeFilter[1];
      }
      if (this.datasetFilters.annotationTypeFilter && this.datasetFilters.annotationTypeFilter.length > 0) {
        params.annotations_filter.types = this.datasetFilters.annotationTypeFilter;
      }
      if (this.datasetFilters.annotationSetFilter && this.datasetFilters.annotationSetFilter.length > 0) {
        params.annotations_filter.images_with_annotation_set_ids = this.datasetFilters.annotationSetFilter.map((set) => set.id);
      }
      if (this.datasetFilters.annotationLabelFilter && this.datasetFilters.annotationLabelFilter.length > 0) {
        params.annotations_filter.images_with_annotation_label_indexes = this.datasetFilters.annotationLabelFilter;
      }
      if (this.datasetFilters.boundingBoxSizeFilter) {
        Object.entries(this.datasetFilters.boundingBoxSizeFilter).forEach(([key, value]) => {
          if (key !== 'type' && value) {
            params.annotations_filter[`${key}_${this.datasetFilters.boundingBoxSizeFilter.type}`] = value;
          }
        });
      }

      // Image files filter
      if (this.datasetFilters.imageNameFilter && this.datasetFilters.imageNameFilter.length > 0) {
        params.image_files_filter.image_name = this.datasetFilters.imageNameFilter;
      }
      if (this.datasetFilters.timeRangeFilter) {
        params.image_files_filter.lower_time = this.datasetFilters.timeRangeFilter[0];
        params.image_files_filter.upper_time = this.datasetFilters.timeRangeFilter[1];
      }
      if (this.datasetFilters.dateRangeFilter) {
        if (this.datasetFilters.dateRangeFilter[0]) {
          params.image_files_filter.lower_date = new Date(this.datasetFilters.dateRangeFilter[0]).toISOString();
        }
        if (this.datasetFilters.dateRangeFilter[1]) {
          params.image_files_filter.upper_date = new Date(this.datasetFilters.dateRangeFilter[1]).toISOString();
        }
      }
      if (this.datasetFilters.cameraSourceFilter && this.datasetFilters.cameraSourceFilter.length > 0) {
        params.image_files_filter.image_sources = this.datasetFilters.cameraSourceFilter;
      }

      if (Object.keys(params.annotations_filter).length === 0) {
        delete params.annotations_filter;
      }

      return params;
    },
    currentLabels() {
      if (this.currentDataset) {
        return this.currentDataset.labels;
      }
      return [];
    },
    currentTypes() {
      if (this.currentDataset) {
        return this.currentDataset.annotation_types;
      }
      return [];
    },
    annotationSets() {
      if (this.currentDataset) {
        return this.currentDataset.annotation_sets;
      }
      return [];
    },
  },
  watch: {
    show() {
      this.$nextTick(() => {
        this.map.updateSize();
      });
    },
    imageList: {
      handler(imageList) {
        this.addMarker(imageList);
      },
    },
    getImagesParams: {
      deep: true,
      async handler(params) {
        this.imageListPromise = new Promise((resolve, reject) => {
          this.getImages(params).then((imageList) => {
            this.imageList = imageList;
            resolve(imageList);
          });
        });
      },
    },
  },
  async created() {
    if (this.getImagesParams) {
      this.imageList = await this.getImages(this.getImagesParams);
    }
  },
  mounted() {
    this.map = new Map({
      target: 'map',
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      view: new View({
        center: [0, 0],
        zoom: 2,
      }),
    });

    if (this.markersSource) {
      this.markersSource.clear();
    }
  },
  methods: {
    async getImages(params) {
      this.controller.abort();
      this.controller = new AbortController();
      const currentController = this.controller;

      const resp = await this.dataConnect.getFilteredImages(params, currentController.signal)
        .catch((error) => {
          if (currentController.signal.aborted) {
            return this.imageList;
          }
          console.log(error);
          alert(error);
          return this.imageList;
        });
      if (!resp || resp.error || !resp.result) {
        if (resp && resp.error) {
          console.error(resp.error);
          alert(resp.error.message);
        }
        return this.imageList;
      }

      return resp.result;
    },
    addMarker(images) {
      if (this.markersSource) {
        this.markersSource.clear();
      }
      const features = [];
      images.forEach((img) => {
        features.push(
          new Feature({
            geometry: new Point(fromLonLat([img.longitude, img.latitude])),
          }),
        );
      });
      this.markersSource = new VectorSource({ features });

      const clusterDistance = 5;
      const minDistanceBetweenClusters = 50;
      this.clusterSource = new Cluster({
        distance: clusterDistance,
        minDistance: minDistanceBetweenClusters,
        source: this.markersSource,
      });

      const styleCache = {};
      this.markersLayer = new VectorLayer({
        source: this.clusterSource,
        style(feature) {
          const size = feature.get('features').length;
          let style = styleCache[size];
          if (!style) {
            style = new Style({
              image: new CircleStyle({
                radius: 7,
                fill: new Fill({
                  color: '#ff4343',
                }),
              }),
              // text: new Text({
              //   text: size.toString(),
              //   fill: new Fill({
              //     color: '#000',
              //   }),
              // }),
            });
            styleCache[size] = style;
          }
          return style;
        },
      });

      this.map.addLayer(this.markersLayer);
    },
  },
};
</script>

<style lang="scss" scoped>

.empty-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
}
.controls-bar {
  display: flex;
  flex-direction: row;
  padding: 10px 20px 10px 20px;
  align-items: center;
  border-bottom: 1px solid #c9c9c9;
  @include themify() {
    background: themed('color-ribbon');
  }
  justify-content: space-between;
}
  #map {
    height: 100%;
    padding: 20px;
  }
</style>
