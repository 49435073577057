<template>
  <!-- eslint-disable max-len -->
  <div
    :id="imageObj.id"
    class="grid-item-container"
  >
    <slot name="header" />
    <div
      class="grid-item"
      @mouseenter="$emit('image-mouseenter', $event)"
      @mouseleave="$emit('image-mouseleave', $event)"
    >
      <slot name="image" />
      <ImageCanvas
        :imageId="imageObj?.id"
        :boxes="boxes"
        :segmentations="segmentations"
        :crop="crop"
        :cropPadding="cropPadding"
        :imageQuality="imageQuality"
        :fetchPriority="fetchPriority"
      />
    </div>
    <slot name="content" />
  </div>
</template>

<script>
import ImageCanvas from '@/components/ImageCanvas.vue';
import useAnnotationColorMap from '@/composables/useAnnotationColorMap.js';
import { toRefs } from 'vue';
import { getColor } from '@/assets/js/utils.js';

export default {
  name: 'BaseGalleryGridItem',
  components: {
    ImageCanvas,
  },
  props: {
    imageObj: {
      type: Object,
      default: () => {},
    },
    annotations: {
      type: [Array, Object],
      default: () => [],
    },
    annotationSets: {
      type: Array,
      default: () => [],
    },
    crop: {
      type: Object,
      default: null,
    },
    cropPadding: {
      type: Number,
      default: 0,
    },
    annotationDisplayType: {
      type: String,
      default: 'set',
    },
    filterAnnotationsBySets: {
      type: Array,
      default: null,
    },
    filterAnnotationsByLabelIndexes: {
      type: Array,
      default: null,
    },
    imageQuality: {
      type: String,
      default: 'thumbnail',
    },
    fetchPriority: {
      type: String,
      default: 'auto',
    },
  },
  emits: [
    'grid-item-clicked', 'image-deleted', 'annotation-list-updated', 'image-mouseenter', 'image-mouseleave',
  ],
  setup(props) {
    const { annotationSets } = toRefs(props);
    const { map: annotationSetColorMap } = useAnnotationColorMap({ items: annotationSets, key: 'id' });
    return {
      annotationSetColorMap,
    };
  },
  data() {
    return {};
  },
  computed: {
    filteredAnnotations() {
      let annotationsArray;
      if (Array.isArray(this.annotations)) {
        annotationsArray = this.annotations;
      } else if (typeof this.annotations === 'object') {
        // Convert object to array if it's not already
        annotationsArray = [this.annotations];
      } else {
        annotationsArray = [];
      }

      return annotationsArray;
    },
    boxes() {
      if (this.filteredAnnotations?.length > 0) {
        return this.filteredAnnotations.filter((anno) => anno.type === 'box' || anno.type === 'auto-box').map((anno) => this.convertAnnotationToCanvasBox(anno));
      }
      return [];
    },
    segmentations() {
      if (this.filteredAnnotations?.length > 0) {
        return this.filteredAnnotations.filter((anno) => anno.type === 'seg' || anno.type === 'auto-seg').map((anno) => this.convertAnnotationToSegmentation(anno));
      }
      return [];
    },
  },
  methods: {
    handleImageClicked() {
      this.$emit('grid-item-clicked', this.imageObj);
    },
    convertAnnotationToCanvasBox(anno) {
      let originX = 0;
      let originY = 0;
      let x = anno.x;
      let y = anno.y;
      const w = anno.w;
      const h = anno.h;
      if (this.annotationSets) {
        const annoTask = this.annotationSets.find((set) => set.id === anno.annotation_set_id);
        if (annoTask) {
          if (annoTask.origin_x) {
            originX = annoTask.origin_x;
            x -= originX * anno.w;
          }
          if (annoTask.origin_y) {
            originY = annoTask.origin_y;
            y -= originY * anno.h;
          }
        }
      }
      let color;
      if (anno.color) {
        color = anno.color;
      } else {
        if (this.annotationDisplayType === 'set' && anno.annotation_set_id) {
          color = this.annotationSetColorMap[anno.annotation_set_id];
        }
        if (this.annotationDisplayType === 'label' && anno.label_index >= 0) {
          color = getColor(anno.label_index);
        }
      }
      let opacity = 1;
      if (anno.opacity) {
        opacity = anno.opacity;
      }

      return {
        x, y, w, h, color, opacity,
      };
    },
    convertAnnotationToSegmentation(anno) {
      const polygons = JSON.parse(anno.polygon);

      let color;
      if (anno.color) {
        color = anno.color;
      } else {
        if (this.annotationDisplayType === 'set' && anno.annotation_set_id) {
          color = this.annotationSetColorMap[anno.annotation_set_id];
        }
        if (this.annotationDisplayType === 'label' && anno.label_index >= 0) {
          color = getColor(anno.label_index);
        }
      }

      return {
        polygons, color,
      };
    },
  },
};
</script>

<style lang="scss" scoped>

.grid-item-container {
  // user-select: none;
  // -moz-user-select: none;
  // -khtml-user-select: none;
  // -webkit-user-select: none;
  // -o-user-select: none;

  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 6px;
  cursor: pointer;
  box-shadow: 1px 2px 6px 1px rgba(0, 0, 0, 0.125), 0 0 1px 1px rgba(0, 0, 0, 0.125);
  margin: 1px;
  transition: transform .4s ease;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  @include themify() {
    background: themed('image-background-fill');
    color: themed('text-color');
  }
}
.grid-item-container:hover {
  @include themify() {
    outline: 1px solid themed('card-list-hover-outline');
  }
}

.grid-item {
  display: flex;
  flex: 1 1 auto;
  position: relative;
  cursor: pointer;
  width: inherit;
  height: 250px;
  max-height: 250px;
  min-height: 0;
}
</style>
