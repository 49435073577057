<template>
  <FilterPopper>
    <template #default="{ menuOpen }">
      <BaseFilterButton
        :name="'Images With Annotation Sets'"
        :active="isActive"
        :tagValue="tagValue"
        :menuOpen="menuOpen"
      />
    </template>
    <template #popper>
      <div v-if="annotationSets && annotationSets.length > 0" class="filter-settings scrollbar">
        <div v-for="(set, i) in annotationSets" :key="i" class="filter-settings__row">
          <input
            :id="`annotationSetFilter-set-${i}`"
            v-model="selectedSets"
            type="checkbox"
            :name="`${set.name}-${i}`"
            :value="set"
          >
          <label :title="`${set.name}`" :for="`set-${i}`">{{ set.name }}</label><br>
        </div>
      </div>
      <div v-else class="filter-settings">
        Please define an annotation set.
      </div>
      <div class="filter-footer">
        <button :disabled="!isActive" class="button-text clear-button" @click="clearSelection">Clear Selection</button>
      </div>
    </template>
  </FilterPopper>
</template>

<script>
import FilterPopper from '@/components/FilterPopper.vue';
import BaseFilterButton from '@/components/BaseFilterButton.vue';

export default {
  name: "AnnotationSetFilter",
  components: {
    FilterPopper,
    BaseFilterButton,
  },
  props: {
    annotationSets: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
  },
  emits: [
    'update:modelValue',
  ],
  data() {
    return {
      selectedSets: [],
    };
  },
  computed: {
    isActive() {
      return this.selectedSets.length > 0;
    },
    tagValue() {
      return this.selectedSets.length.toString();
    },
  },
  watch: {
    annotationSets: {
      deep: true,
      immediate: true,
      handler() {
        this.selectedSets = [];
      },
    },
    modelValue(newVal) {
      this.selectedSets = newVal;
    },
    selectedSets() {
      this.updateFilters();
    },
  },
  methods: {
    updateFilters() {
      this.$emit('update:modelValue', this.selectedSets);
    },
    clearSelection() {
      this.selectedSets = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-settings {
  display: flex;
  flex-direction: column;
  padding: 8px;
  border-radius: inherit;
  gap: 6px;
  min-width: 200px;
  max-width: 300px;
  overflow: auto;

  &__row {
    display: flex;
    align-items: center;
  }

  & input[type=checkbox] {
    margin-right: 12px;
  }

  & label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.filter-footer {
  min-height: 38px;
  width: 100%;
  border-top: 1px solid rgba(140,140,140,0.375);
  padding: 8px;
  text-align: center;

  .clear-button {
    @include themify() {
      color: themed('color-danger');
    }

    &[disabled] {
      @include themify() {
        color: themed('color-disabled');
      }
    }
  }
}
</style>
