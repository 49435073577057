<template>
  <!-- eslint-disable max-len -->
  <div class="header">
    <div class="header__logo" @click="gotoHome()" v-html="auzone_logo" />
    <div class="left">
      <div id="header-module" class="header__module">
        <slot name="header-slot" />
      </div>
    </div>
    <div class="center">
      <div id="header-project" class="header__breadcrumbs">
        <slot name="page-slot" />
      </div>
    </div>
    <div class="menus">
      <WarningMenu />
      <div />
      <ModuleMenu />
      <div />
      <HelpMenu />
      <div />
      <UserMenu />
    </div>
  </div>
</template>

<script>
import { auzone_logo } from "@/assets/js/icons";
import SVGIcon from '@/components/SVGIcon.vue';
import UserMenu from "@/components/UserMenu.vue";
import HelpMenu from "@/components/HelpMenu.vue";
import ModuleMenu from "@/components/ModuleMenuV2.vue";
import WarningMenu from "@/components/WarningMenu.vue";

export default {
  components: {
    SVGIcon,
    ModuleMenu,
    UserMenu,
    HelpMenu,
    WarningMenu,
  },
  props: {
    headerTitle: { String, default: "Welcome" },
    page: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      auzone_logo,
      show_menu: false,
      userInfo: null,
      debugMode: false,
    };
  },
  computed: {
    currentProject() {
      return this.$store.state.projects.currentProject;
    },
    isDebugMode() {
      return this.$store.state.debugMode;
    },
  },
  watch: {
    debugMode() {
      const state = this.$store.state.debugMode;
      this.$store.commit('setDebugMode', !state);
    },
  },
  methods: {
    gotoHome() {
      this.$router.push({ path: '/projects' });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

.header {
  display: flex;
  font-size: 1.5rem;
  gap: 15px;
  box-shadow: 0 1px 3px grey, 0 1px 7px grey;
  z-index: 10;
  background: var(--header-background);
  color: var(--header-text-color);

  .left {
    display: flex;
    min-width: fit-content;
    justify-content: flex-start;
    align-items: center;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  .center {
    display: flex;
    flex: 1 1 0px;
    align-items: center;
    min-width: 0;
    overflow: hidden;
  }

  .menus {
    display: flex;
    min-width: fit-content;
    justify-content: flex-end;
    align-items: center;
    overflow-x: hidden;
    overflow-y: hidden;

    div {
      margin: 0px 10px;
    }
  }

  &__module {
    font-weight: 500;
    letter-spacing: 0.0325em;
  }

  &__breadcrumbs {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: auto;
    height: 70%;
    max-width: 100%;
    line-height: 1;
  }

  &__logo {
    width: 100px;
    min-width: 70px;
    height: 50px;
    padding: 6px 6px 6px 6px;
    cursor: pointer;
  }

  &__select-theme {
    position: relative;
    width: 150px;
    height: 30px;
  }

  &__button {
    padding-right: 10px;
  }

  &__dot-menu {
    display: flex;
    flex: 0 0 auto;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 0 4px;
    background: none;
    border: none;
    cursor: pointer;
    color: var(--header-dot-menu);

    &:hover {
      background: var(--header-button-background-hover);
      color: var(--header-button-color-hover);
    }

    &._active {
      background: var(--header-button-background-hover);
      color: var(--header-button-color-hover);
    }
  }

  &__user {
    display: flex;
    flex: 0 0 auto;
    width: 32px;
    height: 32px;
    cursor: pointer;
    border-radius: 50%;
    background: rgb(5, 60, 143);
    margin-right: 8px;
    justify-content: center;
    align-items: center;
  }

  &__user-letter {
    height: min-content;
    font-size: 1.25rem;
  }
}

.header.switch-label {
  font-size: 20px;
}

</style>
