<template>
  <AnnotationToolViewingMode3DControls
    v-model:show3DTo2DProjection="show3DTo2DProjection"
    :show="true"
    @top-view="handleTopView"
    @front-view="handleFrontView"
    @perspective-camera="switchToPerspectiveCamera"
    @orthographic-camera="switchToOrthographicCamera"
  />
  <TresCanvas
    ref="canvas"
    v-bind="gl"
    render-mode="on-demand"
  >
    <TresPerspectiveCamera
      v-if="cameraType === 'perspective'"
      ref="cameraPerspective"
      :position="cameraPerspectivePosition"
      :up="upDirection"
      :fov="45"
      :look-at="lookAt"
    />
    <TresOrthographicCamera
      v-else-if="cameraType === 'orthographic'"
      ref="cameraOrthographic"
      :up="upDirection"
      :position="cameraOrthographicPosition"
    />
    <OrbitControls
      ref="controls"
      make-default
      :enableDamping="true"
      :dampingFactor="1"
    />
    <TransformControls
      v-if="transformEnabled"
      ref="transformControls"
      :object="transformObject"
      :mode="transformMode"
      @mouse-up="handleTransformMouseUp(transformObject)"
    />
    <TresGroup v-if="showTypes?.pcd" ref="pointGroup">
      <TresMesh
        v-for="(point, pointIndex) in pointArray"
        :key="point.id"
        :position="point.position"
        @click="(el, intersection, pointerEvent) => handlePointClicked(pointIndex, el, intersection, pointerEvent)"
      >
        <TresSphereGeometry :args="[0.05]" />
        <TresMeshBasicMaterial :color="'#FFCC00'" />
      </TresMesh>
    </TresGroup>
    <TresGroup
      v-if="showTypes?.['3dbox']"
      ref="boxGroup"
      :key="invalidateBoxes"
      :invalidateBoxes="invalidateBoxes"
    >
      <TresMesh
        v-for="(box, boxIndex) in boxes3d"
        :ref="(object) => boxRefsFn(object, boxIndex, box)"
        :key="box.id"
        :position="[box.data_json.x, box.data_json.y, box.data_json.z]"
        @click="(el, intersection, pointerEvent) => handleBoxClicked(box, el, intersection, pointerEvent)"
        @pointer-enter="(el) => handleBoxPointerEnter(el)"
        @pointer-leave="(object) => handleBoxPointerLeave(object)"
      >
        <TresBoxGeometry :args="[box.data_json.dx, box.data_json.dy, box.data_json.dz, 1, 1, 1]" />
        <primitive v-if="!invalidateBoxes" :object="getBoxEdges(box.data_json.dx, box.data_json.dy, box.data_json.dz, box.label_index)" />
        <TresLineDashedMaterial
          :color="getLabelColor(box.label_index)"
          :wireframe="false"
          :transparent="true"
          :opacity="0.1"
        />
      </TresMesh>
    </TresGroup>
    <TresAmbientLight :intensity="1" />
    <TresGridHelper
      ref="grid"
      :args="[10, 10]"
      :intensity="0.2"
      :rotateX="Math.PI/2"
    />
    <TresAxesHelper :args="[10]" />
  </TresCanvas>
</template>

<script setup>
import AnnotationToolViewingMode3DControls from '@/components/DatasetComponent/AnnotationTool/AnnotationToolViewingMode3DControls.vue';
import { TresCanvas } from '@tresjs/core';
import { OrbitControls, TransformControls } from '@tresjs/cientos';
import {
  ref, reactive, shallowRef, computed, onMounted, onUnmounted, watch, toRefs, nextTick,
} from 'vue';
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import usePCD from '@/composables/annotationTool/usePCD.js';
import useAnnotationColorMap from '@/composables/useAnnotationColorMap.js';
import * as THREE from 'three';
import gsap from 'gsap';
import { v4 as uuidv4 } from 'uuid';
import useColorParser from '@/composables/useColorParser.js';

const props = defineProps({
  imageObj: { type: Object, default: () => {} },
  annotations: { type: Array, default: () => [] },
  updatedAnnotations: { type: Array, default: () => [] },
  annotationCache: { type: Object, default: () => {} },
  labels: { type: Array, default: () => [] },
  selectedLabel: { type: Object, default: null },
  points: { type: Array, default: null },
  currentTool: { type: String, default: null },
  isActive: { type: Boolean, default: false },
  selectedAnnotationIdentifiers: { type: Array, default: () => [] },
  showTypes: { type: Object, default: () => {} },
  show3DTo2DProjection: { type: Boolean, default: false },
});
const {
  imageObj, annotations, annotationCache, labels, selectedLabel, points, currentTool, isActive, selectedAnnotationIdentifiers, showTypes,
} = toRefs(props);

const emit = defineEmits(['update:updatedAnnotations', 'create-annotation', 'update:selectedAnnotationIdentifiers', 'update:show3DTo2DProjection']);

const {
  pointArray,
  pcdAnnotations,
  parsePCDFile,
} = usePCD();

const {
  parseColor,
} = useColorParser();

const gl = reactive({
  clearColor: '#2B3846',
  shadows: true,
  alpha: false,
  shadowMapType: THREE.BasicShadowMap,
  outputEncoding: THREE.SRGBColorSpace,
  toneMapping: THREE.NoToneMapping,
});

const canvas = ref(null);
const grid = ref(null);
const cameraPerspective = shallowRef(null);
const cameraOrthographic = shallowRef(null);
const controls = ref(null);
const transformControls = ref(null);
const scene = ref(null);
const renderer = ref(null);
const raycaster = ref(null);
const cameraType = ref('perspective');
const cameraPerspectivePosition = ref([-5, 5, 5]);
const cameraOrthographicPosition = ref([-10, 0, 0]);
const upDirection = [0, 0, 1];
const lookAt = ref([0, 0, 0]);
const pointGroup = ref(null);
const transformObject = ref(null);
const transformMode = ref('translate');
const transformEnabled = ref(false);
const selectedObjects = ref({
  boxes: [],
});

const show3DTo2DProjection = ref(false);
watch(show3DTo2DProjection, () => {
  emit('update:show3DTo2DProjection', show3DTo2DProjection.value);
});

const boxRefs = shallowRef([]);
const boxRefsFn = (object, boxIndex, box) => {
  if (object) {
    object.userData.annotation = box;
    boxRefs.value[boxIndex] = object;
  } else {
    boxRefs.value.splice(boxIndex, 1);
  }
};
const invalidateBoxes = ref(false);

const currentCamera = computed(() => {
  if (cameraType.value === 'perspective') {
    return cameraPerspective.value;
  } else if (cameraType.value === 'orthographic') {
    return cameraOrthographic.value;
  }
  return cameraPerspective.value;
});

const boxes3d = computed(() => annotations.value.filter((anno) => anno.type === "3dbox"));

watch(labels, () => {
  deselectAllBoxes();
  invalidateBoxes.value = true;
  nextTick(() => {
    invalidateBoxes.value = false;
  });
}, { deep: true, immediate: true });

watch(annotations, async (newAnnotations) => {
  if (newAnnotations) {
    pcdAnnotations.value = newAnnotations.filter((anno) => anno.type === "pcd");
  }
}, { deep: true });

watch(pcdAnnotations, async () => {
  getPointArray();
});

watch(currentTool, async (newTool) => {
  if (['translate-object', 'scale-object'].includes(currentTool.value)) {
    if (transformEnabled.value && transformObject.value) {
      if (currentTool.value === 'translate-object') transformMode.value = 'translate';
      if (currentTool.value === 'scale-object') transformMode.value = 'scale';
    }
    if (selectedObjects.value.boxes.length > 0) {
      const selectedBox = boxRefs.value.find((boxRef) => boxRef.userData.selected);
      if (selectedBox) {
        transformObject.value = selectedBox;
        transformEnabled.value = true;
      }
    }
  } else {
    transformEnabled.value = false;
    transformObject.value = null;
  }
});

watch(isActive, (newIsActive) => {
  if (newIsActive) {
    enableCanvasClick();
  } else {
    disableCanvasClick();
  }
});

watch(imageObj, (newImageObj) => {
  transformEnabled.value = false;
  transformObject.value = null;
});

watch(selectedAnnotationIdentifiers, () => {
  selectedObjects.value.boxes = selectedObjects.value.boxes.filter((selectedBox) => selectedAnnotationIdentifiers.value.includes(selectedBox.id));
  updateBoxSelection();
}, { deep: true });

onMounted(async () => {
  window.addEventListener('keyup', handleKeyEvent);

  renderer.value = canvas.value.context.renderer.value;
  scene.value = canvas.value.context.scene.value;
  raycaster.value = canvas.value.context.raycaster.value;
  pcdAnnotations.value = annotations.value.filter((anno) => anno.type === "pcd");
  getPointArray();
});

onUnmounted(() => {
  window.removeEventListener('keyup', handleKeyEvent);
});

function handleKeyEvent(event) {
  if (event.key === 'Delete') {
    deleteObjects();
  }
}

async function getPointArray() {
  if (points.value) {
    pointArray.value = points.value;
  } else if (pcdAnnotations.value.length > 0) {
    if (pcdAnnotations.value[0].polygon !== "") {
      pointArray.value = parsePCDFile(pcdAnnotations.value[0].polygon);
    } else {
      const dataConnect = new DatastoreConnect();
      const pcdFile = await dataConnect.getAnnotationFile(pcdAnnotations.value[0].id);
      if (pcdFile) {
        pointArray.value = parsePCDFile(pcdFile);
      }
    }
  } else {
    pointArray.value = [];
  }
}

// function createPoints() {
//   pointArray.value.forEach((point) => {
//     // Create sphere geometry
//     const geometry = new THREE.SphereGeometry(0.05);

//     // Create material with color based on label
//     const material = new THREE.MeshBasicMaterial({ color: getLabelColor(point.label) });

//     // Create mesh
//     const mesh = new THREE.Mesh(geometry, material);
//     mesh.position.copy(new THREE.Vector3(...point.position));

//     // Add click event listener
//     mesh.onClick = (event) => {
//       handlePointClicked(point, mesh, event.intersected?.[0], event);
//     };
//     const { registerObject } = usePointerEventHandler({ scene: canvas.value.context.scene.value, contextParts: canvas.value.context });
//     registerObject(mesh);

//     // Add mesh to the group
//     pointGroup.value.add(mesh);
//   });
// }

function handlePointClicked(pointIndex, el, intersection, pointerEvent) {
  if (currentTool.value === '3d-edit-label' && selectedLabel.value) {
    pointArray.value[pointIndex].label_index = selectedLabel.value.index;
    const material = el.object.material.clone();
    material.color.set(getLabelColor(selectedLabel.value?.index));
    el.object.material = material;
    emit('update:updatedAnnotations', JSON.parse(JSON.stringify(annotations.value)));
  }
}

function handleBoxClicked(box, el, intersection, pointerEvent) {
  if (currentTool.value === '3d-pointer') {
    if (el.object.geometry.type === 'BoxGeometry') {
      selectBox(el.object, box);
    }
  }

  if (['translate-object', 'scale-object'].includes(currentTool.value)) {
    if (currentTool.value === 'translate-object') transformMode.value = 'translate';
    if (currentTool.value === 'scale-object') transformMode.value = 'scale';

    deselectAllBoxes();
    selectBox(el.object, box);
    transformObject.value = el.object;
    transformEnabled.value = true;
  }

  if (currentTool.value === '3d-edit-label') {
    el.object.userData.annotation.label_index = selectedLabel.value.index;
    const material = el.object.material.clone();
    material.color.set(getLabelColor(selectedLabel.value?.index));

    el.object.material = material;
    const lineMaterial = el.object.children[0].material.clone();
    lineMaterial.color.set(getLabelColor(selectedLabel.value?.index));
    el.object.children[0].material = lineMaterial;
    emit('update:updatedAnnotations', JSON.parse(JSON.stringify(annotations.value)));
  }
}

// Handle point colors
const { map: labelColorMap } = useAnnotationColorMap({ items: labels, key: 'index' });
function getLabelColor(index) {
  if (index !== undefined && index !== null) {
    const targetLabel = labels.value.find((e) => e.index === index);
    if (targetLabel && targetLabel.color) {
      return parseColor(targetLabel.color).slice(0, -2);
    } else if (labelColorMap.value[index]) {
      return labelColorMap.value[index];
    }
  }
  return '#250E81';
}

function getBoxEdges(dx, dy, dz, label) {
  const geometry = new THREE.BoxGeometry(dx, dy, dz);
  const edges = new THREE.EdgesGeometry(geometry);
  const line = new THREE.LineSegments(edges, new THREE.LineBasicMaterial({ color: getLabelColor(label) }));
  return line;
}

async function handleTopView() {
  controls.value.value.enableDamping = false;
  controls.value.value.enabled = false;
  gsap.to(currentCamera.value.position, {
    duration: 1,
    x: 0,
    y: 0,
    z: 20,
    ease: 'power3.out',
    onComplete: () => {
      controls.value.value.enableDamping = true;
      controls.value.value.enabled = true;
    },
  });
  gsap.to(currentCamera.value.up, {
    delay: 0.1,
    duration: 1,
    x: 1,
    y: 0,
    z: 0,
    ease: 'power3.out',
  });
  gsap.to(controls.value.value.target, {
    delay: 0.1,
    duration: 1,
    x: 0,
    y: 0,
    z: 0,
    ease: 'power3.out',
  });
}

async function handleFrontView() {
  controls.value.value.enableDamping = false;
  controls.value.value.enabled = false;
  gsap.to(currentCamera.value.position, {
    delay: 0.1,
    duration: 1,
    x: -10,
    y: 0,
    z: 0,
    ease: 'power3.out',
    onComplete: () => {
      controls.value.value.enableDamping = true;
      controls.value.value.enabled = true;
    },
  });
  gsap.to(currentCamera.value.up, {
    delay: 0.1,
    duration: 1,
    x: 0,
    y: 0,
    z: 1,
    ease: 'power3.out',
  });
  gsap.to(controls.value.value.target, {
    delay: 0.1,
    duration: 1,
    x: 0,
    y: 0,
    z: 0,
    ease: 'power3.out',
  });
}

async function switchToPerspectiveCamera() {
  const currentUp = cameraOrthographic.value.up.clone();
  cameraOrthographicPosition.value = cameraOrthographic.value.position.clone();
  cameraPerspectivePosition.value = cameraOrthographicPosition.value;
  cameraPerspectivePosition.value = cameraOrthographicPosition.value;

  // Switch camera type
  cameraType.value = 'perspective';
  await nextTick();

  cameraPerspective.value.up = currentUp;
  cameraPerspective.value.updateProjectionMatrix();

  controls.value.value.object = cameraPerspective.value;
  controls.value.value.update();
}

async function switchToOrthographicCamera() {
  const currentUp = cameraPerspective.value.up.clone();
  cameraPerspectivePosition.value = cameraPerspective.value.position.clone();
  cameraOrthographicPosition.value = cameraPerspectivePosition.value;
  // Get matching perspective to orthographic zoom
  const distance = cameraPerspective.value.position.distanceTo(controls.value.value.target);
  const halfWidth = frustumWidthAtDistance(cameraPerspective.value, distance) / 2;
  const halfHeight = frustumHeightAtDistance(cameraPerspective.value, distance) / 2;

  // Switch camera type
  cameraType.value = 'orthographic';
  await nextTick();

  // Set matching perspective to orthographic zoom
  cameraOrthographic.value.zoom = 1;
  cameraOrthographic.value.top = halfHeight;
  cameraOrthographic.value.bottom = -halfHeight;
  cameraOrthographic.value.left = -halfWidth;
  cameraOrthographic.value.right = halfWidth;
  cameraOrthographic.value.up = currentUp;
  cameraOrthographic.value.updateProjectionMatrix();

  controls.value.value.object = cameraOrthographic.value;
  controls.value.value.update();
}

function frustumHeightAtDistance(camera, distance) {
  const vFov = (camera.fov * Math.PI) / 180;
  return Math.tan(vFov / 2) * distance * 2;
}

function frustumWidthAtDistance(camera, distance) {
  return frustumHeightAtDistance(camera, distance) * camera.aspect;
}

async function handleTransformMouseUp(object) {
  const newAnnotations = JSON.parse(JSON.stringify(annotations.value));
  const transformAnnotation = newAnnotations.find((anno) => anno.id === object.userData.annotation.id);

  transformAnnotation.data_json.x = object.position.x;
  transformAnnotation.data_json.y = object.position.y;
  transformAnnotation.data_json.z = object.position.z;
  transformAnnotation.data_json.dx *= object.scale.x;
  transformAnnotation.data_json.dy *= object.scale.y;
  transformAnnotation.data_json.dz *= object.scale.z;
  object.scale.set(1, 1, 1);

  emit('update:updatedAnnotations', JSON.parse(JSON.stringify(newAnnotations)));

  invalidateBoxes.value = true;
  nextTick(() => {
    invalidateBoxes.value = false;
  });
}

function handleCanvasClick(event) {
  if (currentTool.value === '3d-pointer') {
    // Deselect currently selected boxes
    const intersects = raycaster.value.intersectObjects(scene.value.children);
    const boxIntersects = intersects.filter((i) => i.object.type === 'Mesh' && i.object.geometry.type === 'BoxGeometry');
    if (boxIntersects.length === 0) {
      deselectAllBoxes();
    }
  }

  if (currentTool.value === '3d-box') {
    const intersects = raycaster.value.intersectObjects(scene.value.children);
    const gridIntersects = intersects.filter((i) => i.object.type === 'GridHelper');

    if (gridIntersects.length > 0 && selectedLabel.value) {
      console.log(selectedLabel.value);
      // Create new box at average of grid intersects
      const new3DBox = {
        id: uuidv4(),
        label_name: selectedLabel.value.name,
        label_index: selectedLabel.value.index,
        label_id: selectedLabel.value.id,
        type: "3dbox",
        x: 0,
        y: 0,
        w: 0,
        h: 0,
        score: 1,
        data_json: {
          dx: 0.5,
          dy: 0.5,
          dz: 0.5,
          id: uuidv4(),
          label_name: selectedLabel.value.name,
          label_index: selectedLabel.value.index,
          x: (gridIntersects.map((i) => i.point.x).reduce((a, c) => a + c, 0)) / gridIntersects.length,
          y: (gridIntersects.map((i) => i.point.y).reduce((a, c) => a + c, 0)) / gridIntersects.length,
          z: 0,
        },
        image_id: imageObj.value.id,
      };
      emit('create-annotation', new3DBox);

      // Rerenders the boxes
      invalidateBoxes.value = true;
      nextTick(() => {
        invalidateBoxes.value = false;
      });
    }
  }
}

function disableCanvasClick() {
  const canvasEl = renderer.value.domElement;
  canvasEl.removeEventListener('click', handleCanvasClick);
}

function enableCanvasClick() {
  const canvasEl = renderer.value.domElement;
  canvasEl.addEventListener('click', handleCanvasClick);
}

function handleBoxPointerEnter(el) {
  if (['3d-pointer', '3d-edit-label', 'translate-object', 'scale-object'].includes(currentTool.value) && !el.object.userData.selected) {
    const material = el.object.material.clone();
    material.opacity = 0.225;
    el.object.material = material;
  }
}

function handleBoxPointerLeave(object) {
  if (['3d-pointer', '3d-edit-label', 'translate-object', 'scale-object'].includes(currentTool.value) && !object.userData.selected) {
    const material = object.material.clone();
    material.opacity = 0.1;
    object.material = material;
  }
}

function selectBox(object, box) {
  selectedObjects.value.boxes.push(box);
  emit('update:selectedAnnotationIdentifiers', selectedObjects.value.boxes.map((box) => box.id));
  object.userData.selected = true;
  const material = object.material.clone();
  material.opacity = 0.5;
  object.material = material;
}

function deselectAllBoxes(object, box) {
  boxRefs.value.forEach((o) => {
    if (o.userData.selected) {
      o.userData.selected = false;
      const material = o.material.clone();
      material.opacity = 0.1;
      o.material = material;
    }
  });
  selectedObjects.value.boxes = [];
  emit('update:selectedAnnotationIdentifiers', selectedObjects.value.boxes.map((box) => box.id));
}

function updateBoxSelection() {
  boxRefs.value.forEach((o) => {
    if (selectedAnnotationIdentifiers.value.includes(o.userData.annotation.id)) {
      o.userData.selected = true;
      const material = o.material.clone();
      material.opacity = 0.5;
      o.material = material;
    } else {
      o.userData.selected = false;
      const material = o.material.clone();
      material.opacity = 0.1;
      o.material = material;
    }
  });
}

function deleteObjects() {
  deleteSelectedBoxes(selectedObjects.value.boxes);
}

function deleteSelectedBoxes(selectedBoxes) {
  const updatedAnnotations = annotations.value.filter((box) => !selectedBoxes.map((selectedBox) => selectedBox.id).includes(box.id));
  emit('update:updatedAnnotations', JSON.parse(JSON.stringify(updatedAnnotations)));

  // Rerenders the boxes
  invalidateBoxes.value = true;
  nextTick(() => {
    invalidateBoxes.value = false;
  });
}

</script>

<style lang="scss" scoped>

</style>
