<template>
  <!-- <span>{{ searchBoxClosed }}</span> -->
  <v-combobox
    ref="combobox"
    v-model.trim="searchText"
    dense
    filled
    rounded
    clearable
    multiple
    chips
    density="compact"
    placeholder="Search"
    prepend-inner-icon="mdi-magnify"
    class="shrink expanding-search"
    item-title="name"
    variant="outlined"
    :auto-select-first="true"
    :class="{ closed: searchBoxClosed && searchText.length === 0 }"
    :hide-details="true"
    :items="labels"
    :focused="focused"
    @keyup.enter="onSearch"
    @click:prepend-inner="handleClick"
    @update:focused="handleClick"
    @blur="handleBlur"
  >
    <template #append-inner>
      <IconButton
        :icon="'chevron_left'"
        :width="22"
        :height="22"
        :type="'click'"
        :disabled="searchText.length === 0"
        @click="handleLeft"
        @mousedown.stop=""
      />
      <IconButton
        :icon="'chevron_right'"
        :width="22"
        :height="22"
        :type="'click'"
        :disabled="searchText.length === 0"
        @click="handleRight"
        @mousedown.stop=""
      />
    </template>
  </v-combobox>
</template>

<script setup>
import IconButton from '@/components/IconButton.vue';
import {
  nextTick,
  ref,
  toRefs,
} from 'vue';

const props = defineProps({
  labels: {
    type: Array,
    default: () => [],
  },
});
const {
  labels,
} = toRefs(props);

const emit = defineEmits(['next', 'previous']);

const combobox = ref(null);
const searchText = ref([]);
const searchBoxClosed = ref(true);
const focused = ref(false);

function onSearch() {
  // console.log('search');
}

function handleClick() {
  if (searchBoxClosed.value) {
    searchBoxClosed.value = false;

    nextTick(() => {
      focused.value = true;
    });
  }
}

function handleBlur() {
  focused.value = false;
  searchBoxClosed.value = true;
}

function handleLeft() {
  emit('previous', searchText.value);
}

function handleRight() {
  emit('next', searchText.value);
}

</script>

<style lang="scss">
.v-input.expanding-search {
  transition: max-width 0.5s;
  // max-width: 200px;
  // max-height: inherit;
}

.v-input.expanding-search.closed .v-field {
  cursor: pointer;
}

.v-input.expanding-search .v-field__field {
  max-height: 40px;
  overflow: hidden;
}

.v-input.expanding-search.closed .v-field__field {
  max-width: 0;
}

.v-input.expanding-search.closed .v-field__append-inner {
  max-width: 0;
  visibility: hidden;
}
</style>
