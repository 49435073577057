<template>
  <Modal
    ref="copyImageModal"
    :title="'Copy'"
  >
    <template #modal-main>
      <form
        id="copy-form"
        class="form container scrollbar"
        novalidate
        @change="handleFormChange"
        @submit.prevent=""
      >
        <v-select
          v-model="selectedSrcAnnotationSets"
          clearable
          chips
          label="Source Annotation Sets"
          :items="annotationSets"
          item-title="name"
          item-value="id"
          multiple
          variant="outlined"
        />
        <v-select
          v-model="selectedTypes"
          clearable
          chips
          label="Annotation Types"
          :items="types"
          item-title="name"
          item-value="id"
          multiple
          variant="outlined"
        />
        <div class="row">
          <div class="col-12 settings__control-group">
            <label class="required">Destination Dataset</label>
            <select v-model="destDataset" class="select" required>
              <option :key="'review-select-null'" disabled :value="''">Select a Dataset</option>
              <option v-for="d in datasets" :key="`review-select-${d.id}`" :value="d">
                {{ d.name }}
              </option>
            </select>
          </div>
        </div>
        <div v-if="destDataset" class="row mt-3">
          <div class="col-12 settings__control-group">
            <label class="required">Destination Annotation Set</label>
            <select v-model="destAnnotationSet" class="select" required>
              <option :key="'dest_ann_set-null'" disabled :value="''">Select an Annotation Set</option>
              <option v-for="dest_ann_set in destAnnotationSets" :key="dest_ann_set.id" :value="dest_ann_set">
                {{ dest_ann_set.name }}
              </option>
            </select>
            <!-- <v-combobox
              v-model="destAnnotationSetName"
              class="combobox"
              clearable
              :color="'#250e81'"
              :density="'compact'"
              :hide-details="true"
              :label="'Select or Enter New Set Name'"
              :items="destAnnotationSets"
              variant="outlined"
              @click:clear="() => destAnnotationSetName = ''"
            /> -->
          </div>
        </div>
      </form>
    </template>
    <template #modal-footer>
      <div class="footer">
        <button
          class="button modal-action-button"
          @click="handleCopyClicked"
        >
          Apply
        </button>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import {
  ref, computed, onMounted, toRefs,
} from 'vue';
import Modal from '@/components/Modal.vue';
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';

const props = defineProps({
  datasets: {
    type: Array,
    default: () => [],
  },
  types: {
    type: Array,
    default: () => [],
  },
  annotationSets: {
    type: Array,
    default: () => [],
  },
  selectedImage: {
    type: Object,
    default: null,
  },
  selectedImages: {
    type: Array,
    default: null,
  },
});
const {
  datasets,
  selectedImage,
  selectedImages,
} = toRefs(props);

const copyImageModal = ref(null);
const destDataset = ref('');
const destAnnotationSet = ref('');
const selectedSrcAnnotationSets = ref([]);
const selectedTypes = ref([]);

const destAnnotationSets = computed(() => {
  if (destDataset.value) {
    return destDataset.value.annotation_sets;
  }
  return [];
});

const sequences = computed(() => {
  if (selectedImage.value && selectedImage.value.type === 'sequence') {
    return [selectedImage.value.sequence_id];
  }
  if (selectedImages.value) {
    // TODO
  }
  return [];
});

const images = computed(() => {
  if (selectedImage.value && selectedImage.value.type === 'image') {
    return [selectedImage.value.id];
  }
  if (selectedImages.value) {
    // TODO
  }
  return [];
});

onMounted(() => {
  copyImageModal.value.showModal();
});

async function handleCopyClicked() {
  const dataConnect = new DatastoreConnect();
  await dataConnect.copyImages({
    sequences: sequences.value,
    images: images.value,
    source_annotation_set_ids: selectedSrcAnnotationSets.value,
    dest_dataset_id: destDataset.value.id,
    dest_annotation_set_id: destAnnotationSet.value.id,
    types: selectedTypes.value,
  })
    .then((resp) => {
      if (resp.error !== undefined) {
        throw Error(resp.error.message);
      }
      return resp.result;
    })
    .catch((error) => {
      console.error(error);
      alert(`Failed to Copy\n${error.message}`);
    });
}

</script>

<style lang="scss" scoped>

.form {
  padding: 0.75rem 3rem;
  min-width: 480px;
  overflow-y: scroll;
}

.footer {
  display: flex;
  flex-direction: row;
  padding: 0.75rem 3rem;
  justify-content: flex-end;
}

#copy-form {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 0.75rem 3rem;
  overflow: auto;
  position: relative;
}

</style>
