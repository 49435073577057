<template>
  <div class="controls-bar">
    <DatasetFilter
      v-model="datasetFilters"
      :dataset="currentDataset"
      :annotationSets="annotationSets"
      :labels="currentLabels"
      :types="currentTypes"
      :collapsible="true"
      :showCopyFilteredImagesBtn="permissions && permissions.dataset_write"
      :showDeleteFilteredImagesBtn="permissions && permissions.dataset_write"
      @open-copy-filters="openCopyFilteredImagesModal"
      @delete-filtered-images="openConfirmDeleteFilteredImagesModal"
    />
  </div>
  <GalleryGrid
    ref="galleryGrid"
    v-model:page="currentPage"
    v-model:totalPages="totalPagesCount"
    :imageList="imageList"
    :totalImageCount="totalImageCount"
    :imagesPerPage="imagesPerPage"
    :annotationSets="annotationSets"
    :doneFetch="doneFetch"
  >
    <template #header-start>
      <div class="grid-header-start">
        <button class="button button-sm" @click="openUploadModal"><strong>IMPORT</strong></button>
        <DisplayAnnotationsFilter
          v-model="annotationDisplaySettings"
          :annotationSets="annotationSets"
          :labels="currentLabels"
        />
        <DisplayImageDetails
          v-model="imageDisplaySettings"
        />
      </div>
    </template>
    <template #header-end>
      <div class="grid-header-end">
        <span class="gallery-image-count">{{ `Showing ${minImageNumber} to ${maxImageNumber} of ${totalImageCount} images` }}</span>
        <div class="sort-by">
          <SVGIcon
            class="me-2"
            :iconName="'sort'"
            :width="'20px'"
            :height="'20px'"
            title="Sort Options"
          />
          <select
            v-model="sortBy"
            class="select sort-by__select"
          >
            <option value="id">Date Added</option>
            <option value="name">Filename</option>
          </select>
          <IconButton
            class="sort-by__reverse ms-2"
            :class="{_invert: !reverse}"
            :icon="'arrow_upward'"
            :width="'20px'"
            :height="'20px'"
            :type="'click'"
            title="Reverse Sort Direction"
            @click="reverse = !reverse"
          />
        </div>
      </div>
    </template>
    <template #grid-item>
      <GalleryGridItem2
        v-for="imageObj in imageList"
        :key="imageObj.id"
        :imageObj="imageObj"
        :annotations="imageObj.annotations"
        :annotationSets="annotationSets"
        :annotationDisplayType="colorAnnotationsBy"
        :filterAnnotationsBySets="displayAnnotationSets"
        :filterAnnotationsByLabelIndexes="displayLabels"
        :labels="currentLabels"
        :showName="showImageName"
        v-bind="gridItemDisplayProp"
        @grid-item-clicked="displayImage($event)"
        @delete-image-from-dataset="openConfirmDeleteImageModal"
        @copy="handleCopy"
        @download="handleDownload"
      />
    </template>
    <template #empty-state>
      <GalleryEmptyState
        v-if="!isFetchingImages"
        ref="galleryEmptyState"
        :datasetID="currentDatasetID"
        @import-clicked="$refs.uploadImagesModal.showModal();"
      />
    </template>
    <template #overlay>
      <div v-if="isFetchingImages" id="loading-visualization"><div class="lds-ring"><div /><div /><div /><div /></div></div>
      <GalleryDropZone
        ref="galleryEmptyState"
        :datasetID="currentDatasetID"
        :showIcon="imageList && imageList.length > 0"
        @upload-complete="handleImageUploadComplete"
      />
    </template>
  </GalleryGrid>
  <ConfirmModal
    ref="confirmModal"
    :messageHeader="confirmMessageHeader"
    :message="confirmMessage"
    :buttonClass="'button-delete'"
    :buttonText="buttonText"
    @confirmed="confirmFunction"
  />
  <UploadImagesModal
    ref="uploadImagesModal"
    :datasetID="currentDatasetID"
    :annotationSets="annotationSets"
    @upload-complete="handleImageUploadComplete"
    @upload-error="handleUploadError"
  />
  <DatasetCopyModal
    v-if="showCopyModal"
    ref="copyDatasetModal"
    v-model:show="showCopyModal"
    :datasets="datasets"
    :source="currentDataset"
    :filters="imageFilterParams"
    :showFilters="false"
    :lockSource="true"
    @closed="modalDataset = null"
  />
  <ImageCopyModal
    v-if="showCopyImageModal"
    ref="copyImageModal"
    :datasets="datasets"
    :annotationSets="annotationSets"
    :selectedImage="selectedImage"
    :types="currentTypes"
    @closed="() => {showCopyImageModal = false; selectedImage = null}"
  />
  <DatasetExportModal
    ref="datasetExportModal"
    :datasets="datasets"
    :sequences="sequences"
    :exportDataset="currentDataset"
  />
</template>

<script>
import DisplayAnnotationsFilter from '@/components/DatasetComponent/GalleryComponent/DisplayAnnotationsFilterV2.vue';
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import GalleryGrid from '@/components/DatasetComponent/GalleryComponent/GalleryGrid.vue';
import UploadImagesModal from '@/components/DatasetComponent/UploadImagesModal.vue';
import GalleryGridItem2 from '@/components/DatasetComponent/GalleryComponent/GalleryGridItem2.vue';
import GalleryEmptyState from '@/components/DatasetComponent/GalleryComponent/GalleryEmptyState.vue';
import GalleryDropZone from '@/components/DatasetComponent/GalleryComponent/GalleryDropZone.vue';
import SVGIcon from '@/components/SVGIcon.vue';
import IconButton from '@/components/IconButton.vue';
import ConfirmModal from '@/components/ConfirmModal.vue';
import DatasetCopyModal from "@/components/DatasetComponent/DatasetManagement/DatasetCopyModal.vue";
import DatasetExportModal from "@/components/DatasetComponent/DatasetManagement/DatasetExportModal.vue";
import DisplayImageDetails from '@/components/DatasetComponent/GalleryComponent/DisplayImageDetails.vue';
import useGallery from '@/composables/annotationTool/useGallery.js';
import DatasetFilter from '@/components/DatasetComponent/DatasetFilters/DatasetFilter.vue';
import ImageCopyModal from "@/components/DatasetComponent/GalleryComponent/ImageCopyModal.vue";
import equal from 'deep-equal';

export default {
  name: 'Gallery',
  components: {
    GalleryGrid,
    DatasetFilter,
    DisplayImageDetails,
    DisplayAnnotationsFilter,
    GalleryGridItem2,
    GalleryEmptyState,
    GalleryDropZone,
    UploadImagesModal,
    SVGIcon,
    IconButton,
    ConfirmModal,
    DatasetCopyModal,
    DatasetExportModal,
    ImageCopyModal,
  },
  setup() {
    const {
      datasetFilters,
      annotationDisplaySettings,
      imageDisplaySettings,
      reverse,
      combineSequenceFrames,
      sortBy,
      currentDatasetID,
      internalCurrentDataset,
      currentPage,
      imagesPerPage,
      imageList,
      totalImageCount,
      getImagesParams,
      getImagesFilterParams,
      getImages,
      deleteFilteredImages,
      isFetchingImages,
    } = useGallery();
    combineSequenceFrames.value = true;

    return {
      datasetFilters,
      annotationDisplaySettings,
      imageDisplaySettings,
      reverse,
      sortBy,
      currentDatasetID,
      internalCurrentDataset,
      currentPage,
      imagesPerPage,
      imageList,
      totalImageCount,
      getImagesParams,
      getImagesFilterParams,
      getImages,
      deleteFilteredImages,
      isFetchingImages,
      combineSequenceFrames,
    };
  },
  data() {
    return {
      dataConnect: new DatastoreConnect(this.$store.state.enterpriseServerUrl),
      // annotationSets: null,
      imageListPromise: null,
      mapImageList: [],
      totalPagesCount: 0,
      dropHighlighted: false,
      gallerySelection: [],
      confirmMessage: null,
      confirmMessageHeader: null,
      buttonText: "",
      confirmFunction: null,
      showCopyModal: false,
      showCopyImageModal: false,
      permissions: null,
      selectedImage: null,
      sequences: [],
      showExportModal: false,
      doneFetch: true,
    };
  },
  computed: {
    currentProject() {
      return this.$store.state.projects.currentProject;
    },
    datasets() {
      return this.$store.state.datasets.datasetList;
    },
    currentDataset() {
      return this.$store.state.datasets.currentDataset;
    },
    currentLabels() {
      if (this.currentDataset) {
        return this.currentDataset.labels;
      }
      return [];
    },
    currentTypes() {
      if (this.currentDataset) {
        return this.currentDataset.annotation_types;
      }
      return [];
    },
    annotationSets: {
      get() {
        if (this.currentDataset) {
          return this.currentDataset.annotation_sets;
        }
        return [];
      },
      set(sets) {
        const index = this.datasets.indexOf(this.currentDataset);
        this.$store.commit('datasets/setDatasetAnnotationSets', { index, sets });
      },
    },
    getAnnotationSetsParams() {
      if (!this.currentDataset) {
        return null;
      }

      const params = {
        dataset_id: this.currentDataset.id,
      };

      if (this.currentTab === 'annotations') {
        params.include_empty = true;
      }

      return params;
    },
    gridItemDisplayProp() {
      if (!this.datasetFilters?.displayAnnotationsFilter) {
        return {};
      }
      if (this.datasetFilters?.displayAnnotationsFilter?.displayType === 'byAnnotationSets') {
        return { filterAnnotationsBySets: this.datasetFilters.displayAnnotationsFilter.value };
      }
      if (this.datasetFilters?.displayAnnotationsFilter?.displayType === 'byAnnotationLabels') {
        return { filterAnnotationsByLabelIndexes: this.datasetFilters.displayAnnotationsFilter.value };
      }
      return {};
    },
    colorAnnotationsBy() {
      if (this.annotationDisplaySettings?.colorAnnotationsBy?.type) {
        return this.annotationDisplaySettings?.colorAnnotationsBy?.type;
      }
      return 'set';
    },
    displayAnnotationSets() {
      if (this.annotationDisplaySettings?.displayAnnotationSets) {
        return this.annotationDisplaySettings?.displayAnnotationSets.annotation_sets;
      }

      return [];
    },
    displayLabels() {
      if (this.annotationDisplaySettings?.displayLabels) {
        return this.annotationDisplaySettings?.displayLabels.labels;
      }
      return [];
    },
    showImageName() {
      if (this.imageDisplaySettings?.includes('showName')) {
        return true;
      }
      return false;
    },
    showSequences() {
      if (this.imageDisplaySettings?.includes('showSequences')) {
        return true;
      }
      return false;
    },
    imageParams() {
      return this.getImagesParams();
    },
    imageFilterParams() {
      return this.getImagesFilterParams();
    },
    getImagesCombinedParams() {
      return { ...this.getImagesParams(), ...this.getImagesFilterParams() };
    },
    combinedParams() {
      return {
        ...this.currentDataset,
        ...this.annotationDisplaySettings,
        ...this.imageDisplaySettings,
      };
    },
    minImageNumber() {
      return ((this.currentPage - 1) * this.imagesPerPage) + 1;
    },
    maxImageNumber() {
      return ((this.currentPage - 1) * this.imagesPerPage) + this.imageList.length;
    },
  },
  watch: {
    currentDataset: {
      handler() {
        this.currentPage = 1;
        this.currentDatasetID = this.currentDataset ? this.currentDataset.id : 0;
        this.internalCurrentDataset = this.currentDataset;
      },
    },
    getImagesCombinedParams: {
      deep: true,
      async handler(newVal, oldVal) {
        if (!equal(newVal, oldVal)) {
          this.imageListPromise = await new Promise((resolve, reject) => {
            this.doneFetch = false;
            this.getImages().then((imageList) => {
              this.imageList = imageList;
              resolve(imageList);
              this.doneFetch = true;
            }).catch((e) => {
              console.log(e);
              this.doneFetch = true;
            });
          });
        }
      },
    },
    combinedParams: {
      deep: true,
      async handler() {
        this.$router.replace({
          path: this.$route.path,
          query: {
            image_params: encodeURI(JSON.stringify({
              imagesPerPage: this.imagesPerPage,
              currentPage: this.currentPage > 0 ? this.currentPage : 1,
              sortBy: this.sortBy,
              reverse: this.reverse,
            })),
            filter_params: encodeURI(JSON.stringify(this.datasetFilters)),
            annotation_display: encodeURI(JSON.stringify(this.annotationDisplaySettings)),
            image_display: encodeURI(JSON.stringify(this.imageDisplaySettings)),
          },
        });
      },
    },
    getAnnotationSetsParams: {
      deep: true,
      async handler(params) {
        if (params && params !== undefined) {
          this.annotationSets = await this.getAnnotationSetList(params);
        } else {
          this.annotationSets = [];
        }
      },
    },
    showSequences() {
      this.combineSequenceFrames = this.showSequences;
    },
  },
  mounted() {
    this.currentDatasetID = this.currentDataset ? this.currentDataset.id : 0;
    this.internalCurrentDataset = this.currentDataset;
    if (this.$route.query.dataset) {
      const d = this.datasets.find((e) => e.id === this.$route.query.dataset);
      if (d) {
        this.$store.commit('datasets/setCurrentDataset', d);
      }
    }
    if (this.$route.query.image_params) {
      const image_params = JSON.parse(decodeURI(this.$route.query.image_params));
      this.imagesPerPage = image_params.imagesPerPage;
      this.currentPage = image_params.currentPage;
      this.sortBy = image_params.sortBy;
      this.reverse = image_params.reverse;
    }

    if (this.$route.query.filter_params) {
      this.displayFilters = JSON.parse(decodeURI(this.$route.query.filter_params));
    }

    if (this.$route.query.annotation_display) {
      this.annotationDisplaySettings = JSON.parse(decodeURI(this.$route.query.annotation_display));
    }

    if (this.$route.query.image_display) {
      this.imageDisplaySettings = JSON.parse(decodeURI(this.$route.query.image_display));
    }

    this.GetPermissionsForCurrentUser();
  },
  methods: {
    async GetPermissionsForCurrentUser() {
      const user = this.$store.state.user.user;
      await this.dataConnect.getDatasetAccessForSingleUser({
        dataset_id: this.currentDataset.id,
        username: user.username,
      })
        .then((data) => {
          if (data.result) {
            this.permissions = data.result;
          }
        })
        .catch((e) => console.log(e));
    },
    async getAnnotationSetList(params) {
      const annotationSets = await this.dataConnect.getAnnotationSets(params)
        .catch((error) => {
          // TODO: handle error
          console.log(error);
        });
      if (annotationSets.error || !annotationSets.result) {
        // TODO: handle error
        return {};
      }
      return annotationSets.result;
    },
    // async getLabelList() {
    //   const labels = await this.dataConnect.getLabelList({ dataset_id: this.currentDataset.id })
    //     .catch((error) => {
    //       // TODO: handle error
    //       console.log(error);
    //     });
    //   return labels.result;
    // },
    handleGallerySelectionChanged(selection) {
      this.gallerySelection = selection;
    },
    async handleCopyCompleted() {
      this.datasets = await this.getDatasets();
    },
    async handleAnnotationSetCreated() {
      this.annotationSets = await this.getAnnotationSetList();
    },
    openConfirmDeleteFilteredImagesModal() {
      this.confirmMessage = `Are you sure you want to delete all filtered images`;
      this.confirmMessageHeader = 'Delete Filtered Images';
      // this.toDelete = imageObj;
      this.buttonText = 'Delete';
      this.$refs.confirmModal.showModal();
      this.confirmFunction = () => this.deleteFilteredImages();
    },
    openConfirmDeleteImageModal(imageObj) {
      this.confirmMessage = `Are you sure you want to delete ${this.showSequences && imageObj.sequence_id && imageObj.sequence_id > 0 ? "sequence" : "image"} "${imageObj.name}"?`;
      this.confirmMessageHeader = `Delete ${this.showSequences && imageObj.sequence_id && imageObj.sequence_id > 0 ? "Sequence" : "Image"}`;
      // this.toDelete = imageObj;
      this.buttonText = 'Delete';
      this.$refs.confirmModal.showModal();
      this.confirmFunction = () => this.deleteImageFromDataset(imageObj);
    },
    async deleteImageFromDataset(imageObj) {
      let resp = null;
      if (this.showSequences && imageObj.sequence_id && imageObj.sequence_id > 0) {
        resp = await this.dataConnect.purgeSequence({ sequence_ids: [imageObj.sequence_id] });
      } else {
        resp = await this.dataConnect.deleteImageFromDataset({ image_id: imageObj.id });
      }
      if (!resp || resp.error || !resp.result) {
        if (resp && resp.error) {
          console.error(resp.error);
        }
        alert("Image failed to delete");
      } else {
        this.imageListPromise = new Promise((resolve, reject) => {
          this.getImages().then((imageList) => {
            this.imageList = imageList;
            resolve(imageList);
          });
        });
      }
    },
    getColorByIndexFromScale(index) {
      const hue = index * 137.508 + 60; // use golden angle approximation
      return `hsl(${hue},100%,50%)`;
    },
    getColorByIndex(index) {
      const colors = ['#ffff00', '#00ff00', '#ffa500', '#ff0000', '#48d1cc', '#a0522d', '#ffc0cb', '#ff1493', '#f0e68c', '#1e90ff', '#0000ff', '#00fa9a'];
      return colors[index % 12];
    },
    displayImage(imageObj) {
      this.$router.push({
        name: 'datasets.gallery.viewer',
        query: {
          image_id: encodeURI(JSON.stringify(imageObj.id)),
          annotationDisplaySettings: encodeURI(JSON.stringify(this.annotationDisplaySettings)),
          showSequences: encodeURI(this.imageDisplaySettings.includes('showSequences')),
        },
      });
    },
    openUploadModal() {
      this.$refs.uploadImagesModal.showModal();
    },
    handleImageUploadComplete() {
      this.imageListPromise = new Promise((resolve, reject) => {
        this.getImages().then((imageList) => {
          this.imageList = imageList;
          resolve(imageList);
        });
      });
      this.$refs.uploadImagesModal.closeModal();
    },
    async handleUploadError(data) {
      this.confirmMessage = data.confirmMessage;
      this.confirmMessageHeader = data.confirmMessageHeader;
      this.buttonText = "";
      this.$refs.confirmModal.showModal();
    },
    openCopyFilteredImagesModal() {
      this.showCopyModal = true;
    },
    handleCopy(imageObj) {
      this.selectedImage = imageObj;
      this.showCopyImageModal = true;
    },
    handleDownload(imageObj) {
      if (imageObj.type === 'sequence') {
        this.sequences = [
          {
            "id": imageObj.sequence_id,
            "uuid": imageObj.uuid,
            "name": imageObj.name,
          },
        ];
      }
      this.$refs.datasetExportModal.showModal();
    },
  },
};
</script>

<style lang="scss" scoped>
.controls-bar {
  display: flex;
  flex-direction: row;
  padding: 10px 20px 10px 20px;
  align-items: center;
  border-bottom: 1px solid #c9c9c9;
  @include themify() {
    background: themed('color-ribbon');
  }
  justify-content: space-between;
}
.grid-header-start {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  align-items: center;
  gap: 12px;
}

.grid-header-end {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
}

#dataset-select {
  width: 300px;
}

.gallery-image-count {
  margin-left: 16px;
  font-size: 0.75rem;
  font-weight: 600;
  @include themify() {
    color: themed('body-text-color-secondary');
  }
}

.sort-by {
  display: flex;
  flex-direction: row;
  align-items: center;

  &__select {
    width: 130px;
  }

  &__reverse._invert {
    transform: rotate(180deg);
  }
}

#loading-visualization {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  background: transparent;
}

.lds-ring {
  /* change color here */
  color: var(--color-primary);
}
.lds-ring,
.lds-ring div {
  box-sizing: border-box;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid currentColor;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: currentColor transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
